
/*
** General Styles for HTML tags
*/

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&amp;display=swap");
* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #666666;
}

html,
body,
.wrapper {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0e2b3d;
  font-weight: normal;
  line-height: 1.2;
  font-family: "Heebo", sans-serif;
  letter-spacing: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 27px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}

.scrollToTop {

  width: 50px;
  height: 50px;
  line-height: 30px;
  text-align: center;
    position: fixed;
    bottom: 45px;
    right: 50px;
    z-index: 999;
}

a,
.btn,
button {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  outline: medium none;
}

a img,
iframe {
  border: none;
}

p {
  color: #666666;
  margin: 0 0 15px;
  text-transform: none;
  line-height: 1.7;
}

li {
  font-family: "Heebo", sans-serif;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

a,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
  color: #0e2b3d;
}

a:hover {
  color: #0e2b3d;
}

a {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  text-decoration: none;
  font-family: "Heebo", sans-serif;
  font-weight: 600;
}

b,
strong {
  font-weight: 900;
}

.btn.active,
.btn:active {
  background-image: inherit !important;
}

.btn.focus,
.btn:active:focus,
.btn:active:hover,
.btn:focus,
.btn:hover {
  outline: 0;
}

.btn.circle {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.btn.active,
.btn:active {
  outline: 0;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
  min-height: 50px;
}
/* Default CSS */
.container-medium {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

.container-fill {
  padding: 0 15px;
  margin: auto;
  max-width: 100%;
  padding: 0;
}

@media (min-width: 576px) {
  .scrollToTop {
    width: 50px;
    height: 50px;
    line-height: 30px;
    text-align: center;
      position: fixed;
      bottom: 45px;
      right: 50px;
      z-index: 999;
  }
  .container-medium {
    max-width: 540px;
  }
  
}

@media (min-width: 768px) {
  .container-medium {
    max-width: 720px;
  }
  
}

@media (min-width: 992px) {
  .container-medium {
    max-width: 960px;
  }
  .values-container {
    margin-left: 100px;
    margin-right: 100px;
  }
  
}

@media (min-width: 1200px) {
  .container-medium {
    max-width: 1400px;
    width: 80%;
  }
  .values-container {
    margin-left: 100px;
    margin-right: 100px;
  }
  
}

@media (min-width: 576px) {
  .container-full {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-full {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-full {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-full {
    max-width: 1400px;
    width: 90%;
  }
}

@media (min-width: 576px) {
  .container-fill {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-fill {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-fill {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-fill {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .container-fill {
    padding: 0 15px;
  }
}

.fixed-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.bg-gray {
  background-color: #f3f7fd;
}

.bg-light {
  background-color: #ffffff;
}

.bg-theme {
  background-color: #104cba;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p,
.text-light a {
  color: #ffffff;
}

.shadow {
  position: relative;
  z-index: 1;
  box-shadow: inherit !important;
}

.shadow.dark::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.dark-hard::after {
  background: #000000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.dark-small::after {
  background: #104cba none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.light::after {
  background: #ffffff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme::after {
  background: #104cba none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.shadow.theme-hard::after {
  background: #104cba none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
  position: relative;
  z-index: 1;
}

.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.default-padding-top {
  padding-top: 120px;
}

.default-padding-bottom {
  padding-bottom: 120px;
}

.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}

.default-padding-bottom.bottom-less {
  margin-bottom: -30px;
}

.default-padding-top.bottom-less {
  margin-bottom: -30px;
}

.top-border {
  border-top: 1px solid #e7e7e7;
}

.align-center {
  align-items: center;
}

.btn {
  display: inline-block;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
  font-size: 16px;
}

.btn-md {
  padding: 15px 40px;
}

.btn-sm {
  padding: 10px 35px;
  font-size: 14px;
}

.btn-border-light {
  border: 2px solid #ffffff;
}

.btn-dark {
  background-color: #0e2b3d;
  color: #ffffff;
  border: 2px solid #0e2b3d;
}

.btn-dark.border {
  background-color: transparent;
  color: #0e2b3d;
  border: 2px solid #0e2b3d !important;
}

.btn-dark.border:hover {
  background-color: #0e2b3d;
  color: #ffffff !important;
  border: 2px solid #0e2b3d !important;
}

.btn-gray.border {
  background-color: transparent;
  color: #104cba;
  border: 2px solid #e7e7e7 !important;
}

.btn-gray.border:hover {
  background-color: #104cba;
  color: #ffffff !important;
  border: 2px solid #104cba !important;
}

.btn-gray.effect {
  background: #fafafa;
  border: 1px solid #e7e7e7 !important;
}

.btn.btn-light.effect {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #ffffff;
  color: #0e2b3d;
}

.btn.btn-light.border {
  background: transparent;
  border: 2px solid #ffffff !important;
  color: #ffffff;
}

.btn.btn-light.border:hover {
  background: #ffffff none repeat scroll 0 0 !important;
  border: 2px solid #ffffff !important;
  color: #0e2b3d !important;
}

/* Button Animaton */
.btn-animate {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
}

.btn-animate {
  width: 12rem;
  height: auto;
}

.btn-animate .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3.2rem;
  height: 3.2rem;
  background: #104cba;
  border-radius: 1.625rem;
}

.btn-animate .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.btn-animate .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.btn-animate .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.btn-animate .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #104cba;
  line-height: 1.8;
  text-align: center;
  text-transform: uppercase;
}

.btn-animate:hover .circle {
  width: 100%;
}

.btn-animate:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.btn-animate:hover .button-text {
  color: #fff;
}
/* End Btn Animate */

.bg-theme .btn-standard:hover,
.bg-dark .btn-standard:hover,
.bg-gradient .btn-standard:hover,
.shadow .btn-standard:hover {
  color: #ffffff;
}

.btn-dark:hover,
.btn-dark:focus {
  color: #0e2b3d !important;
  background: transparent;
}

.bg-dark {
  background: #060816 !important;
}

.bg-dark-hard {
  background: #08111e;
}

.bg-gradient {
  background: #104cba;
  background: linear-gradient(
    90deg,
    rgba(0, 16, 45, 1) 0%,
    rgba(0, 89, 252, 1) 56%
  );
}

.btn-theme {
  background-color: #104cba;
  color: #ffffff !important;
  border: 2px solid #104cba;
}

.btn-theme:hover {
  background-color: #060816 !important;
  color: #ffffff !important;
  border: 2px solid #060816 !important;
}

.shadow .btn-theme:hover {
  background-color: #ffffff !important;
  color: #232323 !important;
  border: 2px solid #ffffff !important;
}

.btn-theme.border {
  background-color: transparent;
  color: #0e2b3d !important;
  border: 2px solid #104cba !important;
}

.text-light .btn-theme.border {
  color: #ffffff !important;
}

.btn-theme.border:hover {
  background-color: #104cba !important;
  color: #ffffff !important;
  border: 2px solid #104cba !important;
}

.btn-theme.effect:hover,
.btn-theme.effect:focus {
  background: #104cba none repeat scroll 0 0;
  border: 2px solid #104cba;
  color: #ffffff;
}

.btn.btn-sm-lm {
  font-size: 12px;
  padding: 4px 35px;
}

.bg-theme a.btn-theme.btn:hover,
.bg-theme a.btn-theme.btn:focus {
  border: 2px solid #ffffff;
  color: #ffffff;
}

/* Gradient Button */

.btn-gradient,
.btn-gradient:focus {
  position: relative;
  z-index: 1;
  color: #ffffff !important;
}

.btn-gradient:hover {
  color: #ffffff;
}

.btn-gradient::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  transition: all 0.35s ease-in-out;
  z-index: -1;
}

.btn-gradient i {
  display: inline-block;
  line-height: 28px;
  color: #ffffff;
  font-size: 14px;
  margin-left: 5px;
}

.btn-gradient.icon-normal i {
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: inherit;
  position: relative;
  top: 2px;
  margin-right: 0;
  height: auto;
  width: auto;
}

.btn-gradient.icon-left i {
  margin-left: 0;
  margin-right: 5px;
}

.inc-icon i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 10px;
  border-radius: 50%;
  margin-left: 5px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
}

.btn-gradient.btn-sm {
  padding: 10px 30px;
}

.btn.btn-transparent.border {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.btn.btn-transparent.border:hover {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.07);
  color: #ffffff !important;
}

.relative {
  position: relative;
  z-index: 1;
}

.bg-fixed {
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}

.bg-cover {
  background-position: center center !important;
  background-size: cover !important;
}

.overflow-hidden {
  overflow: hidden;
}

button,
button:focus {
  border: none !important;
  box-shadow: inherit !important;
  outline: inherit !important;
}

header {
  position: relative;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
  position: relative;
  z-index: 1;
  margin-left: 3px;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a {
  border: medium none;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: -2px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

header
  nav.navbar.border.bootsnav.navbar-fixed.no-background
  ul
  li
  a.active::after,
header
  nav.navbar.border.bootsnav.navbar-fixed.no-background
  ul
  li
  a:hover::after {
  width: 100%;
}

header
  nav.navbar.border.bootsnav.navbar-fixed.no-background
  .attr-nav
  ul
  li
  a.active::after,
header
  nav.navbar.border.bootsnav.navbar-fixed.no-background
  .attr-nav
  ul
  li
  a:hover::after {
  width: 0;
}

.attr-nav .social.right li {
  margin-left: 20px;
}

.attr-nav .social li a {
  font-size: 16px;
}

/* Navbar */
nav.bootsnav.navbar-default.info-topbar .navbar-header {
  display: none;
}

nav.bootsnav.navbar-default.info-topbar ul li a {
  margin-right: 30px;
  padding: 35px 0;
}

nav.bootsnav.navbar-default.info-topbar.active-full ul li a {
  margin-right: 0;
  padding: 35px 20px;
}

.attr-nav > a.btn-theme.effect:hover,
.attr-nav > a.btn-theme.effect,
.attr-nav > a.btn-theme.effect:focus {
  background: #104cba none repeat scroll 0 0;
  border: 2px solid #104cba;
  color: #ffffff !important;
}

nav.bootsnav.navbar-default.info-topbar.sticked ul li a {
  margin-right: 30px;
  padding: 35px 0;
}

.attr-nav.social li {
  display: inline-block;
  padding: 25px 0 !important;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.attr-nav.social li a {
  border: 1px solid #f4f4f4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #104cba;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
  padding: 0 !important;
  text-align: center;
  width: 40px;
}

nav.navbar.bootsnav.sticked .attr-nav.social li {
  display: inline-block;
  padding: 20px 0 !important;
}

/* Star Off Canvas nav */

body.overflow {
  z-index: 1;
  height: 100%;
  position: fixed;
}

body.overflow::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #104cba;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  opacity: 0.9;
}

header.ofcanvas-nav {
  position: relative;
}

header.ofcanvas-nav .logo {
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 9;
}

.nav-indicator {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: #104cba;
  color: #ffffff;
  padding: 50px;
}

.nav-indicator.clicked {
  position: fixed;
}

.nav-indicator span {
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
  color: #ffffff;
}

.nav-indicator span,
.nav-indicator span::before,
.nav-indicator span::after {
  display: block;
  width: 30px;
  height: 1px;
  background-color: #ffffff;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.nav-indicator span::before,
.nav-indicator span::after {
  position: absolute;
  content: "";
}

.nav-indicator span::before {
  top: -9px;
}

.nav-indicator span::after {
  top: 9px;
}

.nav-indicator.clicked span {
  background-color: transparent;
}

.nav-indicator.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -moz-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.nav-indicator.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -moz-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.nav-indicator.clicked span:before,
.nav-indicator.clicked span:after {
  background-color: #ffffff;
}

.nav-indicator:hover {
  cursor: pointer;
}

nav.navbar.off-canvas-nav {
  background-color: #ffffff;
  position: fixed;
  z-index: 9;
  top: 0;
  right: -18px;
  height: 100%;
  max-width: 515px;
  width: 100%;
  padding: 100px;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  overflow-y: auto;
  box-shadow: 0 15px 40px -5px rgb(0 0 0 / 10%);
  border-radius: inherit;
}

nav.navbar.off-canvas-nav.show {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

nav.navbar.off-canvas-nav.show ul.main li {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(1) {
  transition-delay: 0.15s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(2) {
  transition-delay: 0.3s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(3) {
  transition-delay: 0.45s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(4) {
  transition-delay: 0.6s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(5) {
  transition-delay: 0.75s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(6) {
  transition-delay: 0.9s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(7) {
  transition-delay: 1.05s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(8) {
  transition-delay: 1.2s;
}

nav.navbar.off-canvas-nav.show ul.main li:nth-child(9) {
  transition-delay: 1.35s;
}

nav.navbar.off-canvas-nav ul.main {
  list-style-type: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

nav.navbar.off-canvas-nav ul.main li {
  margin-bottom: 30px;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav.navbar.off-canvas-nav ul.main li:last-child {
  margin-bottom: 0;
}

nav.navbar.off-canvas-nav.show ul.main li a {
  font-size: 40px;
  font-weight: 800;
  color: #666666;
}

nav.navbar.off-canvas-nav.show ul.main li a:hover {
  color: #104cba;
}

header.ofcanvas-nav .logo-responsive {
  display: none;
}

@media only screen and (max-width: 767px) {
  nav.navbar.off-canvas-nav.show ul.main li {
    margin: 20px;
  }

  nav.navbar.off-canvas-nav.show ul.main li a {
    font-size: 16px;
    font-weight: 600;
  }

  nav.navbar.off-canvas-nav {
    max-width: 300px;
    padding: 100px 30px;
  }

  header.ofcanvas-nav {
    position: relative;
    background: #ffffff;
    height: 100px;
  }

  header.ofcanvas-nav .logo-responsive {
    display: inline-block;
  }

  header.ofcanvas-nav .logo-desktop {
    display: none;
  }

  header.ofcanvas-nav .logo {
    top: 20px;
  }

  body.overflow {
    position: inherit;
  }

  body.overflow::after {
    display: none;
  }

  nav.navbar.off-canvas-nav ul.main {
    position: inherit;
    top: 0;
    transform: inherit;
  }
}
/* End Off Canvas nav */

.site-heading h5 {
  text-transform: uppercase;
  font-weight: 800;
  color: #104cba;
  font-size: 18px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.bg-gradient .site-heading h5 {
  color: #ffffff;
}

.site-heading p {
  margin: 0;
  margin-top: 10px;
}

.site-heading.light p {
  color: #ffffff;
}

.site-heading h2 {
  display: block;
  font-weight: 600;
  position: relative;
  margin-bottom: 0;
  line-height: 1.2;
}

.site-heading.light h2 {
  color: #ffffff;
}

.site-heading .devider {
  display: inline-block;
  width: 50px;
  height: 2px;
  background: #104cba;
  position: relative;
  z-index: 1;
  left: 10px;
}

.bg-gradient .site-heading .devider {
  background: #ffffff;
}

.site-heading.text-light .devider {
  background: #ffffff;
}

.site-heading.light .devider {
  background: #ffffff;
}

.site-heading .devider:before {
  position: absolute;
  left: -15px;
  top: 0;
  content: "";
  height: 2px;
  width: 10px;
  background: #104cba;
}

.site-heading.light .devider:before,
.bg-gradient .site-heading .devider:before {
  background: #ffffff;
}

.heading-divider {
  display: inline-block;
  position: relative;
  height: 5px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #104cba;
  width: 90px;
  overflow: hidden;
}

.heading-divider:after {
  content: "";
  position: absolute;
  left: 0;
  top: -1.1px;
  height: 7px;
  width: 8px;
  background-color: #ffffff;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: infinite-move;
  animation-name: infinite-move;
}

.area-title {
  line-height: 1.2;
}

.bg-gray .heading-divider:after {
  background-color: #f3f7fd;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px;
  padding: 15px;
}

@media only screen and (min-width: 1100px) {
  .left-border::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 70px;
    background: #edf5ff;
    z-index: -1;
  }

  .bg-gray.left-border::before {
    background: #ffffff;
  }
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px;
  padding: 15px;
}

.heading-left {
  margin-bottom: 60px;
}

.heading-left .left-info {
  padding-right: 50px;
  border-right: 1px solid #dddddd;
}

.heading-left .right-info {
  padding-left: 50px;
}

.heading-left h5 {
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #104cba;
  font-size: 18px;
}

.heading-left p {
  margin-top: -5px;
  margin-bottom: 0;
}

.heading-left h2 {
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
}

.heading-left a {
  margin-top: 10px;
}

.area-title {
  line-height: 1.2;
}

/* Fixed Shape */
.fixed-shape-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.fixed-shape-bottom img {
  width: 100%;
}

/* ============================================================== 
     # Top Bar 
=================================================================== */
.language-switcher .dropdown-toggle {
  background: transparent;
  border: none;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px;
  text-transform: capitalize;
}

.language-switcher img {
  padding: 3px;
  box-shadow: 0 0 10px #cccccc;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.language-switcher .dropdown-toggle li {
  display: block;
}

.language-switcher .dropdown-menu li {
  display: block;
  padding: 0;
  border-bottom: 1px solid #e7e7e7;
}

.language-switcher .dropdown-menu li:last-child {
  border: none;
}

.language-switcher .dropdown-menu {
  margin: 0;
  min-width: 200px;
  border-radius: inherit;
  border: 1px solid #e7e7e7;
  left: auto !important;
  right: 0;
}

.nav-box .language-switcher .dropdown-menu {
  top: 58px;
}

@media (min-width: 1024px) {
  .nav-box .language-switcher .dropdown-menu {
    top: 80px;
  }
}

.language-switcher .dropdown-menu.show {
  display: block !important;
}

.language-switcher .dropdown-menu li a {
  padding: 7px 15px;
  text-transform: capitalize;
  display: block;
  font-weight: 600;
}

/* ============================================================== 
     # Top Bar 
=================================================================== */

.top-bar-area.transparent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.top-bar-area.transparent i {
  color: #ffffff !important;
}

.top-bar-area.inc-border {
  border-bottom: 1px solid #e7e7e7;
}

.top-bar-area li {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
  z-index: 1;
}

.top-bar-area li:first-child {
  margin: 0;
  padding: 0;
  border: none;
}

.top-bar-area p {
  margin: 0;
  font-weight: 500;
}

.top-bar-area p a {
  text-decoration: underline;
  font-weight: 600;
}

.top-bar-area.inc-border {
  border-bottom: 1px solid #e7e7e7;
}

.top-bar-area .button a {
  display: inline-block;
  padding: 6px 25px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.top-bar-area li::after {
  position: absolute;
  left: 0;
  top: 5px;
  content: "";
  height: 19px;
  width: 1px;
  border-left: 1px solid #dddddd;
}

.top-bar-area.transparent li::after {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  height: 80px;
  top: -30px;
}

.top-bar-area li:first-child::after {
  display: none;
}

.top-bar-area .item-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar-area .social ul {
  margin-left: 30px;
}

.top-bar-area .social ul li::after {
  display: none;
}

.top-bar-area .social li {
  margin-left: 10px;
}

.top-bar-area.inc-pad {
  padding: 15px 0;
}

.top-bar-area.inc-padding {
  padding: 10px 0;
}

.top-bar-area .button i {
  margin-right: 5px;
}

.top-bar-area .info li i {
  margin-right: 5px;
  color: #104cba;
}

.top-bar-area.fixed {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.top-bar-area.fixe .row {
  align-items: center;
}

.top-bar-area.fixed {
  padding: 20px 0;
}

.top-bar-area .address-info li {
  text-align: left;
}

.top-bar-area .address-info li .icon,
.top-bar-area .address-info li .info {
  display: inline-block;
}

.top-bar-area .address-info li .icon i {
  margin-right: 10px;
}

.top-bar-area .info a {
  margin-left: 20px;
}

.top-bar-area.fixed .container {
  padding-right: 30px;
}

.top-bar-area.bg-dark .info li i,
.top-bar-area.bg-theme .info li i,
.top-bar-area.bg-gradient .info li i {
  color: #ffffff;
}

.top-bar-area.inc-logo {
  padding: 15px 0;
}

.top-bar-area .info-colums ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar-area .info-colums ul .info span {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}

.top-bar-area .info-colums li i {
  font-size: 35px;
}

.top-bar-area .info-colums ul .info {
  font-size: 14px;
  text-align: left;
}

.top-bar-area .info-colums .info,
.top-bar-area .info-colums .icon {
  display: inline-block;
}

.top-bar-area .info-colums ul li {
  text-align: left;
}

.top-bar-area .info-colums ul li .info {
  line-height: 24px;
}

.top-bar-area .info-colums ul li .icon {
  margin-right: 15px;
}

.top-bar-area .info-colums ul li::after {
  height: 90%;
  top: 5%;
}

.top-bar-area.bg-theme .info-colums ul li::after {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar-area .info-colums ul li .icon i {
  color: #104cba;
}

.top-bar-area.bg-theme .info-colums ul li .icon i {
  color: #ffffff;
}

.top-bar-area.bg-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* ============================================================== 
     # Bradcrumb 
=================================================================== */
.breadcrumb-area {
  padding: 150px 0;
}

.breadcrumb-area .breadcrumb {
  background: transparent none repeat scroll 0 0;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 0;
}

.breadcrumb-area h2 {
  display: block;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 40px;
  margin-top: -10px;
}

.breadcrumb > li + li::before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  padding: 0 5px;
  color: #ffffff;
  display: none;
}

.breadcrumb-area .breadcrumb li i {
  margin-right: 5px;
}

.breadcrumb-area .breadcrumb li {
  padding: 0 12px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.breadcrumb-area .breadcrumb li::after {
  color: #ffffff;
  content: "/";
  font-weight: 600;
  position: absolute;
  right: -5px;
  height: 100%;
}

.breadcrumb-area .breadcrumb li:last-child::after {
  display: none;
}

.breadcrumb-area .breadcrumb a,
.breadcrumb-area .breadcrumb li {
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.breadcrumb-area .breadcrumb li.active {
  color: #ffffff;
  text-decoration: underline;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Fixed Shape */
.fixed-shape-top {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.fixed-shape-top img {
  width: 100%;
}

/* ============================================================== 
     # Banner 
=================================================================== */
body,
.banner-area,
.banner-area div {
  height: 700px;
}

.banner-area.auto-height,
.banner-area.auto-height div {
  height: auto;
}

.banner-area.auto-height .content {
  padding: 150px 0;
}

.banner-area .box-cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.banner-area .box-table {
  display: table;
  width: 100%;
}

.banner-area .box-cell,
.banner-area .box-cell div {
  height: auto;
}

.banner-area {
  position: relative;
  overflow: hidden;
}

.banner-area .wavesshape {
  bottom: -2px;
  left: 0;
  position: absolute;
  right: 0;
  top: auto;
  width: 100%;
}

.banner-area .wavesshape.shape {
  bottom: -50px;
  height: auto;
}

.banner-area .content {
  position: relative;
  z-index: 9;
}

.banner-area.wavesshape {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow {
  position: relative;
  z-index: 1;
}

.banner-area.triangle-shadow::before {
  border-bottom: 0 solid transparent;
  border-right: 100vw solid #fff;
  border-top: 80px solid transparent;
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.banner-area.shadow-inner .content {
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-area.content-double .double-items {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.banner-area .banner-carousel .owl-item.center img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.banner-area .banner-carousel .owl-item img {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

/* Animation delays */
.banner-area .carousel-item h1:first-child,
.banner-area .carousel-item h2:first-child,
.banner-area .carousel-item h3:first-child,
.banner-area .carousel-item h4:first-child,
.banner-area .carousel-item h5:first-child,
.banner-area .carousel-item h6:first-child {
  animation-delay: 0.5s;
}

.banner-area .carousel-item h1:nth-child(2),
.banner-area .carousel-item h2:nth-child(2),
.banner-area .carousel-item h3:nth-child(2),
.banner-area .carousel-item h4:nth-child(2),
.banner-area .carousel-item h5:nth-child(2),
.banner-area .carousel-item h6:nth-child(2) {
  animation-delay: 0.9s;
}

.carousel-caption h1:nth-child(3),
.carousel-caption h2:nth-child(3),
.carousel-caption h3:nth-child(3),
.carousel-caption h4:nth-child(3),
.carousel-caption h5:nth-child(3),
.carousel-caption h6:nth-child(3) {
  animation-delay: 0.1s;
}

.banner-area .carousel-item p {
  animation-delay: 1s;
}

.banner-area .carousel-item ul {
  animation-delay: 1.2s;
}

.banner-area .carousel-item a,
.banner-area .carousel-item button {
  animation-delay: 1.4s;
}

/* Carousel Fade Effect */
.carousel-fade .carousel-inner .carousel-item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .carousel-item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
  z-index: 2;
}
/* Slider Zoom Effect */
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}

@-moz-keyframes zoom {
  from {
    -moz-transform: scale(1, 1);
  }

  to {
    -moz-transform: scale(1.2, 1.2);
  }
}

@-o-keyframes zoom {
  from {
    -o-transform: scale(1, 1);
  }

  to {
    -o-transform: scale(1.2, 1.2);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.2, 1.2);
  }
}

.carousel-inner .item > .slider-thumb {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}

.banner-area .carousel-zoom .slider-thumb {
  height: 100%;
  position: absolute;
  width: 100%;
}

#particles-js,
#ripple {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner-area .carousel-control {
  background: transparent none repeat scroll 0 0;
  height: 50px;
  font-size: 30px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 70px;
  z-index: 1;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}

.banner-area .carousel-control.shadow {
  background: transparent none repeat scroll 0 0;
  color: #ffffff;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  opacity: 1;
  padding: 0;
  position: absolute;
  text-shadow: inherit;
  top: 50%;
  width: 50px;
  z-index: 1;
}

.banner-area .carousel-control.shadow::after {
  background: #1e2726 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.banner-area .carousel-control.left {
  left: -80px;
}

.banner-area:hover .carousel-control.left {
  left: 10px;
}

.banner-area:hover .carousel-control.shadow.left {
  left: 20px;
}

.banner-area .carousel-control.right {
  right: -80px;
}

.banner-area:hover .carousel-control.right {
  right: 10px;
}

.banner-area:hover .carousel-control.shadow.right {
  right: 20px;
}

/* Carousel Indicators */

.banner-area .carousel-indicator {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100%;
}

.banner-area .carousel-indicators {
  position: absolute;
  right: 40px;
  left: auto;
  width: auto;
  height: auto;
  top: 50%;
  transform: translate(10px, -50%);
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  -o-transform: translate(10px, -50%);
  bottom: auto;
  margin: 0;
  display: block;
}

.banner-area .carousel-indicators li {
  display: block;
  height: 20px;
  width: 20px;
  margin: 10px 0;
  border: 2px solid #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: transparent;
  position: relative;
  z-index: 1;
}

.banner-area .carousel-indicators li.active {
  border: 2px solid #ffffff;
}

.banner-area .carousel-indicators li::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.banner-area.video-bg-live .mbYTP_wrapper {
  z-index: 1 !important;
}

.double-items > .row div {
  height: auto;
}

/* Content */
.banner-area .item .row div {
  height: auto;
}

.banner-area .bg {
  position: absolute;
  left: 0;
  top: 90px;
  height: 100%;
  width: 50%;
  background-size: cover;
  background-position: center left;
}

.banner-area.content-bg.circle-shape::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 50%;
  background: url(../../assets/img/shape/5.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  opacity: 0.2;
}

.banner-area.content-bg .content {
  padding: 50px;
  background: #ffffff;
  margin-top: 100px;
}

.banner-area .bottom-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.banner-area .bottom-shape img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.banner-area .content {
  z-index: 9;
}

.banner-area .content h4 {
  margin-bottom: 25px;
}

.banner-area .content h2 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 25px;
}

.banner-area .content h2 strong {
  display: block;
}

.banner-area .content a {
  margin-top: 10px;
}

.banner-area .content a.video-play-button {
  top: 55px;
}

.banner-area.text-large .content h2 {
  font-size: 80px;
  line-height: 1.1;
}

.banner-area.text-center .content p {
  padding: 0 10%;
}

.banner-area.top-pad-80 .content {
  padding-top: 80px;
}

.banner-area .background-move {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0.3;
}

@media (min-width: 1300px) {
  .banner-box {
    padding: 50px;
    padding-top: 95px;
    border-radius: 8px;
  }
}

.banner-area .fixed-shape-bottom {
  z-index: -1;
  opacity: 0.1;
}

.banner-area.inc-shape .box-cell {
  position: relative;
  z-index: 1;
}

.banner-area.inc-shape .box-cell::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/img/shape/bg-2.png);
  background-size: cover;
  background-position: bottom;
  z-index: 1;
}

/* Appoinment Form */
.banner-area .appoinment-box {
  padding: 50px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  position: relative;
  z-index: 1;
}

.banner-area .appoinment-box::after {
  position: absolute;
  right: -100px;
  top: -100px;
  height: 400px !important;
  width: 400px;
  background: url(../../assets/img/shape/1-light.png);
  content: "";
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  z-index: -1;
  opacity: 0.15;
}

.banner-area .appoinment-box h4 {
  color: #232323;
  font-weight: 600;
}

.banner-area .appoinment-box p {
  color: #666666;
  margin-bottom: 0;
}

.banner-area .appoinment-box .heading {
  margin-bottom: 30px;
}

.banner-area .appoinment-box input {
  background: #f1f1f1;
  border: none;
  border-radius: 30px;
  padding: 30px;
}

.banner-area .appoinment-box .nice-select {
  background: #f1f1f1;
  border: none;
  border-radius: 30px;
  padding: 5px 30px;
}

.banner-area .appoinment-box .nice-select:after {
  right: 25px;
}

.banner-area .appoinment-box button {
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  color: #ffffff;
  padding: 15px 30px;
  width: 100%;
  border-radius: 30px;
  font-weight: 600;
}

.banner-area .content .button a.video-play-button {
  top: 40px;
  margin-left: 25px;
}

.banner-area .content .button {
  margin-top: 30px;
}

.banner-area.text-uppercase .content h2 {
  font-weight: 800;
  letter-spacing: 1px;
}

.banner-area.text-uppercase .content h2 strong {
  font-size: 60px;
  font-weight: 100;
  margin-top: 15px;
}

.banner-area.double-thumb {
    background-position: top center !important;
}

.banner-area.double-thumb .single-thumb {
    margin-top: 120px;
}

.banner-area.double-thumb .content a {
    margin-top: 25px;
}

/* Animated Shape */

.item-animated {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: infiUpDown;
  animation-name: infiUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes infiUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@-webkit-keyframes infinite-move {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

/* Zoom In/Out Animation */
@-webkit-keyframes zoomeffect {
  0% {
    background-position: center;
    transform: scale(1, 1);
  }

  50% {
    background-position: center;
    transform: scale(2, 2);
  }

  100% {
    background-position: center;
    transform: scale(1, 1);
  }
}

@keyframes zoomeffect {
  0% {
    background-position: center;
    transform: scale(1, 1);
  }

  50% {
    background-position: center;
    transform: scale(2, 2);
  }

  100% {
    background-position: center;
    transform: scale(1, 1);
  }
}

/* ============================================================== 
     # Video Button Play
=================================================================== */
.video-play-button {
  color: #104cba;
  font-size: 30px;
  left: 50%;
  padding-left: 7px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button.theme:before,
.video-play-button.theme:after {
  background: #104cba repeat scroll 0 0;
}

.orange .video-play-button.theme:before,
.orange .video-play-button.theme:after {
  background: #ff5621 repeat scroll 0 0;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video-play-button i {
  display: block;
  position: relative;
  z-index: 3;
  color: #104cba;
}

.video-play-button.theme i {
  color: #ffffff;
}

.video-play-button.relative {
  position: relative;
  display: inline-block;
  left: 30px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  margin-top: 0 !important;
  top: 45px;
  margin-left: 10px;
}

.video-play-button.relative::before,
.video-play-button.relative::after {
  height: 65px;
  width: 65px;
  line-height: 65px;
}

.video-play-button.relative i {
  line-height: 68px;
  font-size: 20px;
}

.video-play-button.relative span {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  left: 100%;
  min-width: 200%;
  text-transform: uppercase;
  margin-top: -7px;
  margin-left: 20px;
}

/* Nice Select CSS */
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 10px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
  color: #0e2b3d;
}

.nice-select .current {
  color: #666666;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -o-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: block;
  margin: 0;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* ============================================================== 
     # Services
=================================================================== */

.services-area {
  position: relative;
  z-index: 1;
}

.services-area.half-bg::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/img/shape/bg-5.png);
  z-index: -1;
  background-size: cover;
  background-position: left;
}

.services-area.half-bg {
  position: relative;
  z-index: 1;
}

.services-area .fixed-shape-left {
  position: absolute;
  left: -10%;
  top: 20%;
  z-index: -1;
  opacity: 0.03;
}

.services-area .services-items .item-grid:nth-child(2) {
  margin-top: 50px;
}

.services-area .services-items .single-item {
  margin-bottom: 30px;
}

.services-area .services-items .item {
  padding: 50px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: relative;
  display: flex;
  overflow: hidden;
}

.services-area .services-items .item::after {
  position: absolute;
  left: -110px;
  top: -110px;
  content: "";
  height: 100%;
  width: 100px;
  background: url(../../assets/img/shape/8.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  transform: rotate(95deg);
}

.services-area .services-items .item i {
  display: inline-block;
  font-size: 50px;
  position: relative;
  z-index: 1;
  color: #104cba;
}

.services-area .services-items .single-item:nth-child(2n) .item i {
  color: #8a49a1;
}

.services-area
  .services-items
  .item-grid:last-child
  .single-item:first-child
  .item
  i {
  color: #57b957;
}

.services-area
  .services-items
  .item-grid:last-child
  .single-item:nth-child(2n)
  .item
  i {
  color: #29b2fe;
}

.services-area .services-items .item h4 {
  font-weight: 600;
  margin-bottom: 20px;
}

.services-area .services-items .item h4 a:hover {
  color: #104cba;
}

.services-area .services-items .item span {
  color: #888888;
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.services-area .services-items .item p {
  margin: 0;
}

.services-area .services-items .item .icon {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 95px;
  border-radius: 5px;
  text-align: center;
  min-width: 90px;
  margin-right: 25px;
  position: relative;
  z-index: 1;
}

.services-area .services-items .item .icon::after {
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  height: 100%;
  width: 50px;
  background: rgba(8, 106, 216, 0.07);
  border-radius: 30px;
  transform: rotate(-45deg);
  margin-left: -25px;
  transition: all 0.35s ease-in-out;
}

.services-area .services-items .item:hover .icon::after {
  left: 0;
  width: 100%;
  border-radius: 50%;
  transform: inherit;
  margin-left: 0;
}

.bottom-content {
  margin-top: 20px;
}

.bottom-content a {
  text-decoration: underline;
}

.bottom-content a:hover {
  color: #104cba;
}

.bottom-content p {
  margin-bottom: 0;
}

/* ============================================================== 
     # Services Version Tow
=================================================================== */

.services-area .services-box .single-item {
  margin-bottom: 30px;
}

.services-area .services-box .single-item:nth-child(2n) .item,
.services-area.grid-layout .services-box .single-item .item,
.services-area .services-box .single-item .item:hover {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.services-area .services-box .item {
  padding: 60px 30px;
  transition: all 0.35s ease-in-out;
}

.services-area .services-box .item i {
  display: inline-block;
  font-size: 40px;
  margin-bottom: 40px;
  height: 80px;
  width: 80px;
  line-height: 85px;
  background: linear-gradient(to bottom right, #00ffff, #3300ff);
  color: #ffffff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  box-shadow: 0px 10px 30px 0px rgba(44, 130, 237, 0.4);
}

.services-area .services-box .item i::after {
  position: absolute;
  left: -20px;
  top: -10px;
  content: "";
  height: 40px;
  width: 40px;
  background: #104cba;
  z-index: -1;
  opacity: 0.1;
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
}

.services-area .services-box .item:hover i::after,
.services-area .services-box .single-item:nth-child(2n) .item i::after {
  position: absolute;
  left: -10px;
  top: -10px;
  content: "";
  height: 120%;
  width: 120%;
  opacity: 0.1;
}

.services-area.grid-layout .services-box .single-item .item i::after {
  position: absolute;
  left: -10px;
  top: -10px;
  content: "";
  height: 120%;
  width: 120%;
  opacity: 0.1;
}

.services-area .services-box .item p {
  margin: 0;
}

/* ============================================================== 
     # Services Version Three
=================================================================== */

.services-area .services-content .single-item {
  margin-bottom: 30px;
}

.services-area .services-content .item {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  padding: 50px 25px;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border: 2px solid #e7e7e7;
  overflow: hidden;
}

.services-area .services-content .item::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -100%;
  content: "";
  height: 100%;
  width: 100%;
  background: #104cba;
  transition: all 0.35s ease-in-out;
  z-index: -1;
}

.services-area .services-content .item:hover::before {
  bottom: 0;
}

.services-area .services-content .item:hover a,
.services-area .services-content .item:hover p {
  color: #ffffff;
}

.services-area .services-content .item::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/img/shape/7.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.04;
  background-position: left top;
}

.services-area .services-content .item:hover::after {
  background: url(../../assets/img/shape/7-light.png);
  opacity: 0.1;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.services-area .services-content .item img {
  margin: auto auto 30px;
  height: 120px;
}

.services-area .services-content .item p {
  margin: 0;
}

.services-area .services-content .item a,
.services-area .services-content .item p {
  transition: all 0.35s ease-in-out;
}

/* ============================================================== 
     # Services Area Version Four
=================================================================== */

.service-area .service-box .single-item {
  margin-bottom: 30px;
}

.service-area .service-box .single-item .item {
  padding: 0 30px;
}

.service-area .service-box .item .thumb {
  display: inline-block;
  background: #ffffff;
  height: 200px;
  width: 200px;
  line-height: 200px;
  -webkit-clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
  box-shadow: 0px 0px 38px 0px rgb(0 0 0 / 8%);
  margin-bottom: 30px;
}

.service-area .service-box .item img {
  height: 80px;
}

/* ============================================================== 
     # Default Services Area
=================================================================== */
.default-services-area .item {
    padding: 60px 30px;
    background: #ffffff;
    border: 2px solid #e7e7e7;
}

.default-services-area .single-item {
    margin-bottom: 30px;
}

.default-services-area .item i {
    display: inline-block;
    font-size: 50px;
    margin-bottom: 30px;
        background: linear-gradient(90deg, rgba(0, 16, 45, 1) 0%, rgba(0, 89, 252, 1) 56%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.default-services-area .item h4 {
    font-size: 18px;
}

.default-services-area .item p {
  margin: 0;
}

.default-services-area h5 {
    text-transform: uppercase;
    color: #086ad8;
    font-weight: 600;
}

.default-services-area h2 {
    font-weight: 600;
    margin-bottom: 25px;
}

.default-services-area a {
    margin-top: 15px;
}


/* ============================================================== 
     # Services Details Area
=================================================================== */

.services-details-items h2,
.services-details-items h3,
.services-details-items h4,
.services-details-items h5,
.services-details-items h6 {
  font-weight: 600;
}

.services-details-items .services-sidebar .single-widget {
  margin-top: 50px;
}

.services-details-items .services-sidebar .single-widget:first-child {
  margin-top: 0;
}

.services-details-items .services-sidebar .widget-title {
  font-weight: 800;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 20px;
  display: inline-block;
  padding-bottom: 15px;
}

.services-details-items .services-sidebar .widget-title::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 50px;
  border-bottom: 2px solid #086ad8;
}

.services-details-items .services-sidebar .services-list a {
  display: block;
  padding: 18px 25px;
  background: #ffffff;
  margin-top: 15px;
  position: relative;
  z-index: 1;
  font-weight: 500;
  overflow: hidden;
  border: 3px solid #e7e7e7;
  border-radius: 5px;
}

.services-details-items .services-sidebar .services-list a::after {
  position: absolute;
  right: 25px;
  top: 50%;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  transform: translateY(-50%);
  font-size: 20px;
}

.services-details-items .services-sidebar .services-list li.current-item a,
.services-details-items .services-sidebar .services-list li a:hover {
  border: 3px solid #086ad8;
}

.services-details-items .services-sidebar {
  padding-right: 35px;
}

.services-details-items .services-sidebar .single-widget.quick-contact {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.services-details-items .services-sidebar .single-widget.quick-contact::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #232323;
  opacity: 0.7;
}

.services-details-items
  .services-sidebar
  .single-widget.quick-contact
  .content {
  padding: 50px 30px;
  position: relative;
  z-index: 1;
}

.services-details-items
  .services-sidebar
  .single-widget.quick-contact
  .content
  i {
  display: inline-block;
  font-size: 25px;
  color: #ffffff;
  margin-bottom: 35px;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background: #086ad8;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  text-align: center;
}

.services-details-items
  .services-sidebar
  .single-widget.quick-contact
  .content
  i::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: #086ad8;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

.services-details-items
  .services-sidebar
  .single-widget.quick-contact
  .content
  p {
  color: #ffffffd1;
}

.services-details-items
  .services-sidebar
  .single-widget.quick-contact
  .content
  h2 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  margin-top: 25px;
}

.services-details-items .services-sidebar .single-widget.brochure ul {
  margin-top: 15px;
}

.services-details-items .services-sidebar .single-widget.brochure a {
  display: flex;
  align-items: center;
  margin-top: 15px;
  background: #086ad8;
  color: #ffffff;
  padding: 20px 30px;
  text-transform: uppercase;
  font-size: 13px;
}

.services-details-items .services-sidebar .single-widget.brochure a i {
  font-size: 20px;
  font-weight: 600;
  margin-right: 16px;
}

.services-details-items
  .services-sidebar
  .single-widget.brochure
  li:first-child
  a {
  margin-top: 0;
}

.services-details-items
  .services-sidebar
  .single-widget.brochure
  li:last-child
  a {
  background: #060816;
}

.services-details-items .services-sidebar .single-widget.brochure a:hover,
.services-details-items
  .services-sidebar
  .single-widget.brochure
  li:last-child
  a:hover {
  background: #086ad8;
}

.services-details-items .services-single-content h2 {
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 25px;
}

.services-details-area .features .col-lg-6 {
  display: flex;
}

.services-details-area .features i {
  display: inline-block;
  font-size: 50px;
  color: #086ad8;
  margin-right: 25px;
}

.services-details-area .features h4 {
  font-weight: 600;
}

.services-details-area .features p {
  margin: 0;
}

.services-details-area .features {
  margin: 40px 0;
}

.services-details-area .services-single-content ul {
  list-style: disc;
  margin-left: 20px;
}

.services-details-area .services-single-content ul li {
  line-height: 30px;
}

/* ============================================================== 
     # About Area
=================================================================== */

.about-area .thumb {
  position: relative;
  padding: 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -30px;
}

.about-area .thumb img {
  border-radius: 50%;
  border: 15px solid #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
}

.about-area .thumb::after {
  position: absolute;
  left: -50px;
  top: 40px;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/img/shape/9.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
}

.about-area .info {
  padding-left: 35px;
}

.about-area .info h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #104cba;
  margin-bottom: 25px;
}

.about-area .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.about-area .info a {
  margin-top: 10px;
}

.about-area ul.achivement {
  margin-top: 30px;
}

.about-area ul.achivement li {
  display: inline-block;
  margin-right: 40px;
  border-right: 1px solid #e7e7e7;
  padding-right: 40px;
  float: left;
}

.about-area ul.achivement li:last-child {
  padding: 0;
  margin: 0;
  border: none;
}

.about-area ul.achivement li .counter {
  position: relative;
}

.about-area ul.achivement li .counter {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 50px;
  font-weight: 700;
  color: #232323;
  line-height: 1;
}

.about-area ul.achivement li .counter .operator {
  font-size: 40px;
  margin-left: 2px;
}

.about-area ul.achivement li .medium {
  font-weight: 600;
  color: #232323;
}

/* About Version Two */

.about-area .fixed-shape {
  position: absolute;
  left: -10%;
  top: 80%;
  opacity: 0.03;
}

.about-area .thumb-box {
  position: relative;
  z-index: 1;
}

.about-area .thumb-box img {
  padding-right: 40%;
}

.about-area .experience {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  padding: 50px;
  background: #ffffff;
  text-align: center;
  border-radius: 8px;
}

.about-area .experience h1 {
  font-size: 120px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  line-height: 100px;
}

.about-area .experience h1 span {
  font-size: 40px;
  line-height: normal;
  position: relative;
  top: -20px;
  margin-left: 5px;
}

.about-area .experience h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.about-area .info.left {
  padding-left: 0;
}

.about-area .info.left ul.achivement li {
  width: 50%;
  margin: 0;
  padding: 0 30px;
}

.about-area .info.left ul.achivement {
  margin: 0 -15px;
  margin-top: 0;
}

.about-area ul.achivement li .fun-fact {
  position: relative;
}

.about-area ul.achivement li .fun-fact i {
  position: absolute;
  right: 0;
  opacity: 0.5;
}

.about-area ul.achivement li .fun-fact i.fas.fa-gem,
.about-area ul.achivement li .fun-fact i.fas.fa-building {
  font-weight: 500;
}

.about-area ul.achivement li .fun-fact p {
  margin-bottom: 0;
  margin-top: 20px;
}

.about-area .info .progress-box .progress {
  height: 5px;
  overflow: inherit;
}

.about-area .info .progress-box h5 {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #232323;
  margin-bottom: 15px;
}

.about-area .info .progress-box {
  margin-top: 40px;
  overflow: inherit;
  text-align: left;
}

.about-area .info .progress-box:first-child {
  margin-top: 0;
}

.about-area .info .progress-box .progress .progress-bar {
  position: relative;
  overflow: inherit;
  background: linear-gradient(
    90deg,
    rgba(9, 70, 139, 1) 0%,
    rgba(30, 132, 246, 1) 100%
  );
}

.about-area .info .progress-box .progress .progress-bar span {
  position: absolute;
  right: 0;
  bottom: 15px;
  background: #104cba;
  padding: 0px 8px;
  font-weight: 600;
  font-size: 12px;
}

.about-area .info .progress-box .progress .progress-bar span::after {
  position: absolute;
  left: 5px;
  bottom: -5px;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #104cba;
}

/* About Version Three */
.about-area .thumb-items {
  position: relative;
  z-index: 1;
}

.about-area .thumb-items .video {
  display: inline-block;
  position: absolute;
  left: -30px;
  bottom: 55px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.about-area .thumb-items .video a {
  display: block;
  padding: 30px;
}

.about-area .thumb-items .video .relative.video-play-button.item-center {
  top: 32px;
  margin-right: 20px;
}

.about-area .thumb-items .video span {
  position: relative;
  top: -3px;
}

.about-area .about-content .info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 65px;
}

.about-area .about-content .item .title {
  display: flex;
  align-items: center;
}

.about-area .about-content .item h4 {
  font-weight: 600;
  margin: 0;
}

.about-area .about-content .item p {
  margin-bottom: 0;
}

.about-area .about-content .item .title {
  margin-bottom: 20px;
}

.about-area .about-content .item i {
  display: inline-block;
  font-size: 40px;
  margin-right: 15px;
  color: #104cba;
}

.about-area .about-content .top-info .col-lg-6:last-child i {
  color: #57b957;
}

.about-area .about-content .bottom-info {
  border-top: 1px dashed #dddddd;
  margin-top: 30px;
  padding-top: 30px;
  position: relative;
}

.clients-carousel-3-col .owl-item img {
  width: auto;
  margin: auto;
}

.about-area .about-content .bottom-info.inc-bg {
  position: relative;
  z-index: 1;
  border: none;
}

.about-area .about-content .bottom-info.inc-bg::after {
  position: absolute;
  top: 0;
  left: -200%;
  content: "";
  height: 1000px;
  width: 600%;
  background: #f3f7fd;
  z-index: -1;
}

/* ============================================================== 
    About Version Three 
=================================================================== */
.about-area.version-three .thumbs {
  position: relative;
  left: -10%;
  padding-right: 35px;
}

.about-area.version-three .thumbs img {
  max-width: 140%;
}

.about-area.version-three ul li {
  color: #232323;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  padding-left: 65px;
}

.about-area.version-three ul li:last-child {
  margin-bottom: 0;
}

.about-area.version-three ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f12a";
  font-family: "Flaticon";
  font-weight: 600;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: rgba(8, 106, 216, 1);
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
}

.about-area.version-three ul li h4 {
  text-transform: inherit;
  font-weight: 600;
  color: #232323;
  margin-bottom: 10px;
}

.about-area.version-three ul {
  overflow: hidden;
  margin-top: 30px;
}

/* ============================================================== 
    Faq 
=================================================================== */

.faq-area .thumb {
  position: relative;
  z-index: 1;
}

.faq-area .thumb::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 400px;
  width: 400px;
  border: 50px solid #104cba;
  z-index: -1;
  border-radius: 50%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: infiUpDown;
  animation-name: infiUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  margin-left: -200px;
  margin-top: -200px;
}

.faq-area .faq-content {
  padding-left: 35px;
}

.faq-area .faq-content h5 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #104cba;
}

.faq-area .faq-content h2 {
  font-weight: 600;
  margin-bottom: 30px;
}

.accordion .card-header h4:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 600;
}

.accordion .card-header h4.collapsed:after {
  content: "\f105";
  font-weight: 600;
  color: #999999;
}

.faq-area .faq-content .card {
  border: none;
  margin-bottom: 31px;
  overflow: inherit;
  background: transparent;
  border-radius: 5px;
  padding: 0;
}

.faq-area .faq-content .card:last-child {
  margin-bottom: 0;
}

.faq-area .faq-content .card .card-header {
  border: none;
  background: transparent;
  padding: 0;
}

.faq-area .faq-content .card .card-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  padding-right: 50px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.faq-area .faq-content .card .card-body {
  padding-left: 30px;
  padding: 0;
  padding-top: 15px;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px;
}

.faq-area .faq-content .card .card-body p:last-child {
  margin-bottom: 0;
}

.faq-area .faq-content .card .card-header h4 strong {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #104cba;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  min-width: 40px;
}

.faq-area .faq-content .card .card-header h4 strong::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: #104cba;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

/* ============================================================== 
     # Technology Index
=================================================================== */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .technology-area .single-item {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
}

.technology-area .fixed-shape-bottom {
  z-index: -1;
  opacity: 0.2;
}

.technology-area .single-item {
  margin-bottom: 30px;
}

.technology-area a {
  background: #ffffff;
  padding: 50px 15px;
  border: 1px solid #e7e7e7;
  display: block;
  padding-bottom: 0;
  position: relative;
  margin-bottom: 15px;
  border-radius: 5px;
}

.technology-area a i {
  display: block;
  font-size: 50px;
  color: #104cba;
  margin-bottom: 15px;
  font-weight: 500;
}

.technology-area a h5 {
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  display: inline-block;
  background: #ffffff;
  padding: 10px 25px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  bottom: -30px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}

.technology-area a h5::after {
  position: absolute;
  left: -100%;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  z-index: -1;
  border-radius: 30px;
  transition: all 0.25s ease-in-out;
}

.technology-area a:hover h5 {
  color: #ffffff;
  border: 1px solid transparent;
}

.technology-area a:hover h5::after {
  left: 0;
}

/* ============================================================== 
     # Fun Ractor
=================================================================== */

.fun-fact-area .fun-fact-items .counter {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
  justify-content: center;
}

.fun-fact {
  font-family: "Heebo", sans-serif;
}

.fun-fact-area .fun-fact-items .counter .operator {
  font-size: 40px;
  margin-left: 2px;
}

.fun-fact-area .fun-fact-items .medium {
  font-weight: 600;
  margin-top: 8px;
  display: block;
}

/* ============================================================== 
     # Clients
=================================================================== */
.clients-area .clients-carousel {
  position: relative;
}

.clients-area .clients-carousel img {
  width: auto;
  margin: auto;
}

.clients-area .clients-carousel::after {
  position: absolute;
  left: -50px;
  top: 50%;
  content: "";
  height: 200px;
  width: 200px;
  background: url(../../assets/img/shape/10.png);
  z-index: -1;
  transform: translateY(-50%);
  opacity: 0.5;
  background-size: contain;
  background-position: center;
}

/* ============================================================== 
     # Our Process
=================================================================== */
@media only screen and (min-width: 1367px) {
  .work-process-area .container-full {
    margin-left: calc((100% - 1140px) / 2);
    width: auto;
    min-width: auto;
    max-width: inherit;
  }
}

.work-process-area .fixed-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.03;
}

.work-process-area .process-items .single-item {
  margin-bottom: 30px;
}

.work-process-area .process-items .item .top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.work-process-area .process-items .item i {
  font-size: 50px;
  opacity: 0.3;
}

.work-process-area .process-items .item span {
  font-size: 50px;
  display: block;
  font-weight: 800;
  text-shadow: -5px 5px 0px #eceefe;
  position: relative;
  line-height: 1;
  color: #104cba;
  font-family: "Heebo", sans-serif;
}

.work-process-area .process-items .single-item:nth-child(2n) .item .top {
  margin-top: 30px;
  margin-bottom: 0;
}

.work-process-area .process-items .item {
  position: relative;
  padding: 60px 30px;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  background: #ffffff;
}

.work-process-area .process-items .item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 3px;
  width: 0;
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  transition: all 0.35s ease-in-out;
}

.work-process-area .process-items .item:hover::after {
  width: 100%;
}

.work-process-area .process-items .item p {
  margin: 0;
}

.work-process-area .process-items .item h5 {
  font-weight: 600;
}

/* ============================================================== 
     # Process Standard
=================================================================== */

.process-standard-area {
  overflow: hidden;
}

.process-standard-area .item .icon {
    position: relative;
    z-index: 1;
    display: inline-block;
    height: 150px;
    line-height: 150px;
    width: 150px;
    border: 2px dashed rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}

.process-standard-area .item .icon i {
    font-size: 50px;
}

.process-standard-area .item h4 {
    margin: 0;
    margin-top: 30px;
    font-weight: 600;
    font-size: 20px;
}

.process-standard-area .item .icon span {
    position: absolute;
    right: 0;
    top: 0;
    background: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #086ad8;
    border-radius: 50%;
    font-weight: 900;
    font-size: 14px;
}

.process-standard-area .item .icon span::after {
    position: absolute;
    left: -8px;
    top: -8px;
    content: "";
    height: 56px;
    width: 56px;
    background: #4c89fc;
    z-index: -1;
    border-radius: 50%;
}

.process-standard-area .single-item:nth-child(2n) .item span {
    top: auto;
    bottom: 0;
}

.process-standard-area .single-item {
    margin-top: 100px;
}

.process-standard-area .single-item:nth-child(2n) {
    margin-top: 0;
}

.process-standard-area .single-item::after {
    margin-left: -50px;
    position: absolute;
    left: 100%;
    top: -17px;
    content: "";
    height: 100px;
    width: 100px;
    background: url(../../assets/img/shape/arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    transform: rotate(11deg);
    opacity: 0.15;
}

.process-standard-area .single-item:last-child::after {
    display: none;
}

.process-standard-area .single-item:nth-child(2n)::after {
    transform: rotate(56deg);
    top: 75px;
}


/* ============================================================== 
     # Choose Us
=================================================================== */

.choose-us-area.overflow-hidden .info .contact {
  position: relative;
  z-index: 1;
}

.choose-us-area.overflow-hidden .info .contact::after {
  position: absolute;
  left: -200%;
  top: 0;
  content: "";
  height: 500%;
  width: 600%;
  background: #ffffff;
  z-index: -1;
}

.choose-us-area .thumb {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.choose-us-area.reverse .thumb {
  padding-left: 35px;
}

.choose-us-area .info {
  margin-top: -50px;
}

.choose-us-area .thumb .content {
  position: absolute;
  right: 0;
  bottom: -50px;
  background: url(../../assets/img/shape/13.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 100px;
  margin-right: -100px;
  text-align: center;
}

.choose-us-area .thumb .content h2 {
  color: #ffffff;
  font-weight: 800;
  margin-bottom: 0;
  font-size: 46px;
}

.choose-us-area .thumb .content h2 span {
  font-size: 35px;
  line-height: normal;
  position: relative;
  top: -10px;
  font-weight: 400;
  left: 5px;
}

.choose-us-area .thumb .content h5 {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}

.choose-us-area .thumb img {
  border-radius: 5px;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
}

.choose-us-area.reverse .thumb {
  margin: 0;
}

.choose-us-area.reverse .thumb img {
  box-shadow: inherit;
  max-width: 120%;
}

.choose-us-area .info h5 {
  text-transform: uppercase;
  color: #104cba;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.choose-us-area .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.choose-us-area .info ul li {
  font-weight: 600;
  color: #232323;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
}

.choose-us-area .info ul li:last-child {
  margin-bottom: 0;
}

.choose-us-area .info ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f12a";
  font-family: "Flaticon";
  font-weight: 600;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: rgba(8, 106, 216, 0.1);
  text-align: center;
  border-radius: 50%;
  color: rgba(8, 106, 216, 1);
  font-size: 12px;
}

.choose-us-area .info ul {
  margin-top: 25px;
}

.choose-us-area.inc-skill .info .contact {
  border: none;
  margin-top: 35px;
  padding-top: 0;
}

.choose-us-area .info .contact {
  display: flex;
  border-top: 1px dashed #dddddd;
  margin-top: 25px;
  padding-top: 25px;
  justify-content: space-between;
  align-items: center;
}

.choose-us-area .info .contact p,
.choose-us-area .info .contact h4 {
  margin: 0;
}

.choose-us-area .info .contact h4 {
  font-weight: 600;
}

.choose-us-area .info .contact i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #104cba;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  margin-right: 5px;
}

.choose-us-area .info .contact i::after {
  position: absolute;
  left: -10%;
  top: -10%;
  content: "";
  height: 120%;
  width: 120%;
  background: #104cba;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
}

.choose-us-area .skill-items h5 {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #232323;
  margin-bottom: 15px;
}

.choose-us-area .skill-items .progress-box {
  margin-top: 30px;
  overflow: inherit;
  text-align: left;
}

.choose-us-area .skill-items .progress-box .progress {
  height: 5px;
  overflow: inherit;
}

.choose-us-area .skill-items .progress-box .progress .progress-bar {
  position: relative;
  overflow: inherit;
  background: linear-gradient(
    90deg,
    rgba(9, 70, 139, 1) 0%,
    rgba(30, 132, 246, 1) 100%
  );
}

.choose-us-area .skill-items .progress-box .progress .progress-bar span {
  position: absolute;
  right: 0;
  bottom: 15px;
  background: #104cba;
  padding: 0px 8px;
  font-weight: 600;
  font-size: 12px;
}

.choose-us-area .skill-items .progress-box .progress .progress-bar span::after {
  position: absolute;
  left: 5px;
  bottom: -5px;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #104cba;
}

/* ============================================================== 
     # Team Members
=================================================================== */
.team-area .team-items .single-item {
  margin-bottom: 30px;
}

.team-area .team-items .single-item:nth-child(even) {
  margin-top: 50px;
}

.team-area.grid-layout .team-items .single-item:nth-child(even) {
  margin-top: 0;
}

.team-area .team-items .single-item:nth-child(even) .info {
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
}

.team-area.grid-layout .team-items .single-item:nth-child(even) .info {
  background: #ffffff;
}

.team-area .team-items .single-item .item .info h5,
.team-area .team-items .single-item .item .info span,
.team-area .team-items .owl-item .item .info h5,
.team-area .team-items .owl-item .item .info span {
  transition: all 0.35s ease-in-out;
}

.team-area .team-items .single-item:nth-child(even) .info h5,
.team-area .team-items .single-item:nth-child(even) .info span,
.team-area .team-items .single-item .item:hover .info h5,
.team-area .team-items .single-item .item:hover .info span,
.team-area .team-items .owl-item .item:hover .info h5,
.team-area .team-items .owl-item .item:hover .info span {
  color: #ffffff;
}

.team-area.grid-layout .team-items .single-item:nth-child(even) .info h5,
.team-area.grid-layout .team-items .single-item .item:hover .info h5 {
  color: #232323;
}

.team-area.grid-layout .team-items .single-item:nth-child(even) .info span,
.team-area.grid-layout .team-items .single-item .item:hover .info span {
  color: #999999;
}

.team-area.grid-layout .team-items .single-item .item:hover .info h5,
.team-area.grid-layout .team-items .single-item .item:hover .info span {
  color: #ffffff;
}

.team-area .team-items .item .info-box {
  padding: 0 30px;
  position: relative;
  top: -50px;
  margin-bottom: -50px;
  z-index: 1;
}

.team-area .team-items .item .info {
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.team-area.carousel-shadow .team-items .item .info {
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

.team-area .team-items .item .info::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  transition: all 0.35s ease-in-out;
  z-index: -1;
}

.team-area .team-items .item:hover .info::after {
  height: 100%;
}

.team-area .team-items img {
  border-radius: 8px;
}

.team-area .team-items .item .info h5 {
  font-weight: 600;
  margin-bottom: 5px;
}

.team-area .team-items .item .info span {
  color: #999999;
  font-weight: 500;
  font-size: 15px;
}

.team-area .team-items .thumb {
  overflow: hidden;
  position: relative;
}

.team-area .team-items .thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 150%)
    repeat scroll 0 0;
  border-radius: 5px;
  transition: all 0.35s ease-in-out;
}

.team-area .team-items .item:hover .thumb::after {
  height: 100%;
}

.team-area .team-items .thumb .social {
  position: absolute;
  left: 0;
  bottom: -30px;
  padding: 30px;
  right: 0;
  transition: all 0.35s ease-in-out;
  z-index: 1;
}

.team-area .team-items .thumb .social ul li {
  display: inline-block;
  margin: 0 5px;
}

.team-area .team-items .item:hover .thumb .social {
  bottom: 40px;
}

.team-area .team-items .thumb .social ul li a {
  display: inline-block;
  color: #ffffff;
  height: 45px;
  width: 45px;
  line-height: 48px;
  border-radius: 50%;
}

.team-area .team-items .thumb .social ul li.facebook a {
  background: #3b5998 none repeat scroll 0 0;
}

.team-area .team-items .thumb .social ul li.twitter a {
  background: #1da1f2 none repeat scroll 0 0;
}

.team-area .team-items .thumb .social ul li.pinterest a {
  background: #bd081c none repeat scroll 0 0;
}

.team-area .team-items .thumb .social ul li.g-plus a {
  background: #db4437 none repeat scroll 0 0;
}

.team-area .team-items .thumb .social ul li.linkedin a {
  background: #0077b5 none repeat scroll 0 0;
}

.team-area .team-items .thumb .social ul li.instagram a {
  background: #3f729b none repeat scroll 0 0;
}

/* Team Carousel */
.team-area .team-carousel {
  position: relative;
  z-index: 1;
}

.team-area .team-carousel::after {
  position: absolute;
  left: -100px;
  top: -100px;
  content: "";
  background: url(../../assets/img/shape/10.png);
  background-size: contain;
  height: 300px;
  width: 300px;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.5;
  transform: rotate(45deg);
}

.team-area .team-items .team-carousel.owl-carousel .owl-dots {
  margin-top: 20px;
}

.team-area .team-items .team-carousel.owl-carousel .owl-dot {
  margin-bottom: -10px;
  margin-top: 20px;
}

.team-area .team-items .team-carousel.owl-carousel .owl-dot span {
  display: block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border: 2px solid #e7e7e7;
}

.team-area .team-items .team-carousel.owl-carousel .owl-dot.active span::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background: #437eeb;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* ============================================================== 
     # Team Single
=================================================================== */

.team-single-area .right-info {
  padding-left: 35px;
}

.team-single-area .right-info h2 {
  font-weight: 600;
  margin-bottom: 10px;
}

.team-single-area .right-info span {
  display: block;
  color: #086ad8;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.team-single-area .right-info ul {
  margin-top: 25px;
}

.team-single-area .right-info ul li {
  margin-top: 10px;
  color: #232323;
}

.team-single-area .right-info ul li a {
  font-weight: 400;
}

.team-single-area .right-info ul li a:hover {
  color: #086ad8;
}

.team-single-area .right-info .social {
  display: flex;
  margin-top: 25px;
  font-weight: 600;
  align-items: center;
}

.team-single-area .right-info .social h4 {
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 25px;
}

.team-single-area .right-info .social ul {
  margin: 0;
}

.team-single-area .right-info .social ul li {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
}

.team-single-area .right-info .social ul li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 47px;
  background: #e7e7e7;
  text-align: center;
  border-radius: 50%;
}

.team-single-area .bottom-info {
    margin-top: 50px;
    border-top: 1px solid #e7e7e7;
    padding-top: 50px;
}

.team-single-area .bottom-info h2 {
    font-weight: 600;
}

.team-single-area .bottom-info p {
    margin: 0;
}

/* ============================================================== 
     # Testimonials
=================================================================== */

.testimonials-area .testimonials-carousel::after {
  position: absolute;
  left: -100px;
  top: -100px;
  content: "";
  height: 200px;
  width: 200px;
  background: url(../../assets/img/shape/10.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.testimonials-area .testimonials-carousel .item .thumb {
  width: 150px;
  min-width: 150px;
  margin-right: 30px;
  position: relative;
  z-index: 1;
  height: 150px;
  overflow: hidden;
}

.testimonials-area .testimonials-carousel .item .thumb img {
  -webkit-clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
}

.testimonials-area .testimonials-carousel .item .thumb i {
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: #104cba;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}

.testimonials-area .testimonials-carousel .item {
  display: flex;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  padding: 50px 30px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.testimonials-area .testimonials-carousel .item .bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
  margin-top: 20px;
}

.testimonials-area .testimonials-carousel .item .bottom h5 {
  font-weight: 600;
  margin-bottom: 0;
}

.testimonials-area .testimonials-carousel .item .bottom span {
  color: #104cba;
  font-size: 14px;
}

.testimonials-area .testimonials-carousel .item .bottom i {
  font-size: 14px;
  color: #104cba;
}

.testimonials-area .testimonials-carousel {
  position: relative;
}

.testimonials-area .testimonials-carousel .owl-nav {
  position: absolute;
  right: 0;
  top: -90px;
  margin: 0;
}

.testimonials-area .testimonials-carousel .owl-nav .owl-next,
.testimonials-area .testimonials-carousel .owl-prev {
  margin: 0;
  padding: 0;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  border: 2px solid #e7e7e7;
  color: #104cba;
  background: #ffffff;
}

.testimonials-area .testimonials-carousel .owl-nav .owl-next:hover,
.testimonials-area .testimonials-carousel .owl-prev:hover {
  background: #ffffff;
  color: #104cba;
}

/* ============================================================== 
     # Case Studies
=================================================================== */

.case-studies-area.half-bg::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 30%;
  width: 100%;
  background: #ffffff;
}

.case-studies-area img {
  border-radius: 5px;
}

.case-studies-area .case-carousel .item {
  position: relative;
  overflow: hidden;
}

.case-studies-area .case-carousel .item .info {
  position: absolute;
  left: 0;
  bottom: -100%;
  right: 0;
  padding: 30px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  opacity: 0;
}

.case-studies-area .case-carousel .owl-item.center .item .info {
  opacity: 1;
  bottom: 0;
}

.case-studies-area .case-carousel .item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 60%;
  width: 100%;
  border-radius: 0 0 5px 5px;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 150%)
    repeat scroll 0 0;
}

.case-studies-area .case-carousel .item .info .tags a {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.case-studies-area .case-carousel .owl-nav {
  margin: 0;
}

.case-studies-area .case-carousel .owl-nav .owl-prev,
.case-studies-area .case-carousel .owl-nav .owl-next {
  background: transparent none repeat scroll 0 0;
  color: #ffffff;
  font-size: 30px;
  height: 40px;
  left: -50px;
  line-height: 40px;
  margin: -20px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  display: inline-block;
  transition: all 0.35s ease-in-out;
  opacity: 0;
}

.case-studies-area .case-carousel .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.case-studies-area .case-carousel:hover .owl-nav .owl-prev {
  left: 20px;
  opacity: 1;
}

.case-studies-area .case-carousel:hover .owl-nav .owl-next {
  right: 20px;
  left: auto;
  opacity: 1;
}

/* ============================================================== 
     # Case Studies Version Two
=================================================================== */

.case-studies-area.grid-items #portfolio-grid {
  margin: -15px;
}

.case-studies-area.grid-items .case-items.colums-3 .pf-item {
  float: left;
  padding: 15px;
  width: 33.3333%;
}

.case-studies-area.grid-items .case-items.colums-2 .pf-item {
  float: left;
  padding: 15px;
  width: 50%;
}

.case-studies-area.grid-items .case-items.colums-4 .pf-item {
  float: left;
  padding: 15px;
  width: 25%;
}

.case-studies-area.grid-items .case-items.colums-6 .pf-item {
  float: left;
  padding: 15px;
  width: 16.6666%;
}

.case-items-area .pf-item .item {
  overflow: hidden;
  position: relative;
}

.case-items-area .pf-item .item img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.case-items-area .pf-item .item:hover .thumb img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.case-items-area .pf-item .item .info {
  position: absolute;
  left: 0;
  bottom: -100%;
  right: 0;
  padding: 30px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  color: #ffffff;
}

.case-items-area .pf-item .item .info h4 {
  margin-bottom: 0;
}

.case-items-area .pf-item .item .info a {
  color: #ffffff;
}

.case-items-area .pf-item .item:hover .info {
  bottom: 0;
  opacity: 1;
}

.case-items-area .pf-item .item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 100%;
  border-radius: 5px;
  transition: all 0.35s ease-in-out;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 150%)
    repeat scroll 0 0;
}

.case-items-area .pf-item .item:hover::after {
  height: 100%;
  border-radius: 5px;
}

.case-items-area .pf-item .item .info .tags a {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.case-items-area .pf-item .item .thumb {
  position: relative;
}

.case-items-area .pf-item .item .thumb a {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
  height: 45px;
  width: 45px;
  line-height: 49px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  color: #104cba;
  overflow: inherit;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}

.case-items-area .pf-item .item:hover .thumb a {
  opacity: 1;
  right: 30px;
}

.case-items-area .pf-item .item .thumb a::after {
  position: absolute;
  left: -10%;
  top: -10%;
  height: 120%;
  width: 120%;
  content: "";
  background: #ffffff;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
}

/* ============================================================== 
     # Project Details
=================================================================== */

.project-details-area .top-info {
  position: relative;
  margin-top: 50px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.project-details-area .top-info .left-info {
  padding-right: 35px;
}

.project-details-area .top-info p:last-child {
  margin-bottom: 0;
}

.project-details-area .project-info h2,
.project-details-area .project-info h3,
.project-details-area .project-info h4,
.project-details-area .project-info h5 {
  font-weight: 700;
  margin-bottom: 25px;
}

.project-details-area .project-info > ul li {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #232323;
}

.project-details-area .project-info > ul li i {
  display: inline-block;
  margin-right: 14px;
  color: #086ad8;
  font-size: 16px;
}

.project-details-area .project-info > ul {
  display: inline-block;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-gap: 30px 30px;
}

.project-details-area .project-info > ul li span {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  position: relative;
  font-size: 15px;
  display: block;
  margin-top: 2px;
  color: #666666;
}

.project-details-area .project-info .share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed rgba(255, 255, 255, 0.5);
  margin-top: 30px;
  padding-top: 30px;
}

.project-details-area .project-info .share ul li {
  display: inline-block;
  margin-right: 8px;
}

.project-details-area .project-info .share ul li a {
  display: inline-block;
  color: #ffffff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

.project-details-area .project-info .share ul li.facebook a {
  background: #3b5998 none repeat scroll 0 0;
}

.project-details-area .project-info .share ul li.twitter a {
  background: #1da1f2 none repeat scroll 0 0;
}

.project-details-area .project-info .share ul li.pinterest a {
  background: #bd081c none repeat scroll 0 0;
}

.project-details-area .project-info .share ul li.g-plus a {
  background: #db4437 none repeat scroll 0 0;
}

.project-details-area .project-info .share ul li.linkedin a {
  background: #0077b5 none repeat scroll 0 0;
}

.project-details-area .project-info .share h4 {
  margin: 0;
  padding-right: 50px;
}

.project-details-area .main-content {
  margin-top: 30px;
}

.project-details-area .main-content h2,
.project-details-area .main-content h3,
.project-details-area .main-content h4,
.project-details-area .main-content h5 {
  font-weight: 600;
  margin-bottom: 25px;
}

.project-details-area .main-content .row img {
  margin-top: 30px;
}

.project-details-area .top-info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

/* ============================================================== 
     # Pricing
=================================================================== */

.pricing-area.half-bg {
  position: relative;
  z-index: 1;
}

.pricing-area.half-bg::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  z-index: -1;
}

.pricing-area.half-bg::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: url(../../assets/img/shape/bg-9.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  opacity: 0.1;
}

.pricing-area .fixed-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 40%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.pricing-area {
  position: relative;
  z-index: 1;
}

.pricing-area .pricing .single-item {
  position: relative;
  z-index: 1;
}

.pricing-area .pricing .single-item::after {
  position: absolute;
  right: 0;
  top: 50px;
  bottom: 50px;
  border-right: 1px solid #e7e7e7;
  content: "";
}

.pricing-area .pricing .single-item:last-child::after {
  display: none;
}

.pricing-area .pricing .pricing-item ul {
  display: inline-block;
  text-align: left;
}

.pricing-area .pricing {
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.pricing-area .pricing .pricing-item {
  padding: 50px;
}

.pricing-area .pricing .pricing-item ul h5 {
  font-weight: 600;
  text-transform: capitalize;
  color: #666666;
}

.pricing-area .pricing .pricing-item ul h2 {
  font-size: 60px;
  font-weight: 600;
  padding-left: 5px;
  margin-bottom: 30px;
  line-height: 1;
}

.pricing-area .pricing .pricing-item ul h2 sub {
  font-size: 16px;
  left: -10px;
}

.pricing-area .pricing .pricing-item ul h2 sup {
  font-size: 16px;
  top: -30px;
  right: 5px;
}

.pricing-area .pricing .pricing-item a {
  margin-top: 15px;
}

.pricing-area .pricing .pricing-item ul li {
  margin-bottom: 15px;
}

.pricing-area .pricing .pricing-item ul li i {
  font-size: 14px;
  color: #104cba;
  margin-right: 5px;
}

.pricing-area .pricing .pricing-item ul li i.fa-minus-circle {
  color: #e3306f;
}

/* ============================================================== 
     # Blog
=================================================================== */

.blog-area .single-item {
  margin-bottom: 30px;
}

.blog-area .item {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 8px;
}

.blog-area .blog-items .item .thumb {
  position: relative;
  padding: 15px;
}

.blog-area .blog-items .item .thumb .date {
  position: absolute;
  left: 30px;
  bottom: -30px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  font-size: 36px;
  color: #104cba;
  font-weight: 800;
  border-radius: 5px;
  line-height: 1;
  font-family: "Heebo", sans-serif;
}

.blog-area .blog-items .item .thumb .date span {
  display: block;
  font-size: 16px;
  color: #232323;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 5px;
}

.blog-area .blog-items .info {
  padding: 30px;
  padding-top: 50px;
}

.blog-area .blog-items .info h2,
.blog-area .blog-items .info h3,
.blog-area .blog-items .info h4 {
  font-weight: 600;
}

.blog-area .blog-items .info h2 a:hover,
.blog-area .blog-items .info h3 a:hover,
.blog-area .blog-items .info h4 a:hover {
  color: #104cba;
}

.blog-area.full-blog .blog-items .info {
  padding-top: 15px;
}

.blog-area .blog-items .item .thumb img {
  border-radius: 5px;
}

.blog-area .blog-items .info .meta ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  font-weight: 500;
  color: #232323;
  font-size: 13px;
}

.blog-area .blog-items .info .meta ul li i {
  font-weight: 500;
  margin-right: 2px;
  color: #104cba;
}

.blog-area .blog-items .info .meta ul li a:hover,
.blog-area .blog-items .info h4 a:hover,
.blog-area .btn-more:hover {
  color: #104cba;
}

.blog-area.content-less .btn-more {
  color: #104cba;
}

.blog-area .blog-items .info .meta {
  margin-bottom: 15px;
}

.blog-area .blog-items .info .meta a {
  font-weight: 500;
}

.blog-area .btn-more {
  text-transform: capitalize;
}

.blog-area .btn-more i {
  position: relative;
  top: 2px;
  margin-left: 1px;
}

.blog-area .blog-items .info .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px;
  padding-top: 30px;
}

.blog-area .blog-items .info .bottom img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 7px;
  border: 1px solid #e7e7e7;
  padding: 3px;
  max-width: 60px;
}

/* Sidebar */

.blog-area.left-sidebar .blog-content {
  float: right;
}

.blog-area.left-sidebar .sidebar {
  padding-right: 35px;
}

.blog-area.right-sidebar .sidebar {
  padding-left: 35px;
}

.blog-area .sidebar .title {
  display: block;
}

.blog-area .sidebar .title h4 {
  font-weight: 800;
  margin-bottom: 30px;
  margin-top: -5px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 20px;
  display: inline-block;
  padding-bottom: 15px;
}

.blog-area .sidebar .title h4::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 50px;
  border-bottom: 2px solid #104cba;
}

.blog-area .sidebar input[type="text"] {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
  min-height: 60px;
  border-radius: 30px;
}

.blog-area .sidebar form {
  position: relative;
}

.blog-area .sidebar button[type="submit"] {
  background: #104cba;
  border: medium none;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
  min-height: 50px;
  width: 50px;
  position: absolute;
  right: 5px;
  text-transform: uppercase;
  top: 5px;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  border-radius: 50%;
}

.blog-area .sidebar input[type="submit"]:hover {
  background: #104cba none repeat scroll 0 0;
}

.blog-area .sidebar .sidebar-item {
  float: left;
  margin-bottom: 50px;
  width: 100%;
  background: #ffffff;
}

.blog-area .sidebar .sidebar-item:last-child {
  margin-bottom: 0;
}

.blog-area .sidebar .sidebar-item.category .sidebar-info,
.blog-area .sidebar .sidebar-item.archives .sidebar-info {
  margin-top: -5px;
}

.blog-area .sidebar .sidebar-item.category li {
  display: block;
  position: relative;
  margin-top: 13px;
}

.blog-area .sidebar .sidebar-item.category li a {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 700;
  color: #666666;
}

.blog-area .sidebar .sidebar-item li a:hover {
  color: #104cba;
}

.blog-area .sidebar .sidebar-item.category li a span {
  color: #b5b5b5;
  font-size: 14px;
  margin-left: 5px;
}

.blog-area .sidebar .sidebar-item.category li:first-child a span {
  top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child a::after {
  top: 0;
}

.blog-area .sidebar .sidebar-item.category li:last-child {
  border: medium none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sidebar-item.recent-post li a {
  color: #333333;
  display: block;
  font-weight: 600;
}

.sidebar-item.recent-post li a:last-child {
  display: inline-block;
}

.sidebar-item.recent-post .meta-title {
  margin-top: 5px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
}

.sidebar-item.recent-post .meta-title i {
  margin-right: 3px;
  font-weight: 500;
  color: #104cba;
}

.sidebar-item.recent-post li a:hover {
  color: #104cba;
}

.sidebar-item.recent-post li a span {
  display: inline-block;
  color: #002359;
}

.sidebar-item.recent-post li span {
  display: inline-block;
  text-transform: capitalize;
  font-size: 13px;
  color: #888888;
  font-weight: 500;
}

.sidebar-item.recent-post .meta-title a {
  color: #999999;
}

.sidebar-item.recent-post li {
  color: #cdd0d3;
}

.sidebar-item.recent-post li {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.sidebar-item.recent-post li:last-child {
  border: medium none;
  margin: 0;
  padding: 0;
}

.sidebar-item.recent-post li:last-child {
  margin: 0;
}

.sidebar-item.recent-post li .thumb {
  display: table-cell;
  padding-top: 5px;
  vertical-align: top;
  width: 80px;
}

.sidebar-item.recent-post .thumb img {
  width: 100%;
  border-radius: 5px;
}

.sidebar-item.recent-post li .info {
  box-shadow: inherit;
  color: #837f7e;
  display: table-cell;
  line-height: 26px;
  padding: 0;
  padding-left: 25px;
  vertical-align: top;
  padding-top: 0 !important;
}

.sidebar-item.recent-post li .info a {
  text-decoration: inherit;
  font-weight: 600;
}

.blog-area .sidebar-item.gallery ul {
  margin: -7px;
  overflow: hidden;
}

.sidebar-item.gallery ul li {
  box-sizing: border-box;
  float: left;
  padding: 7px;
  width: 33.333%;
}

.sidebar-item.gallery ul li img {
  min-height: 70px;
  width: 100%;
}

.sidebar-item.archives ul li {
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.sidebar-item.archives ul li:last-child {
  margin-bottom: 0;
}

.sidebar-item.archives ul li a {
  display: inline-block;
  font-weight: 800;
  text-transform: capitalize;
  z-index: 1;
  padding-left: 25px;
  font-size: 14px;
  color: #666666;
}

.sidebar-item.archives ul li a::after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "\f07b";
  font-family: "Font Awesome 5 Free";
  height: 8px;
  left: 0;
  position: absolute;
  width: 8px;
  font-weight: 500;
}

.sidebar-item.social-sidebar ul {
  margin-bottom: -10px;
  overflow: hidden;
}

.sidebar-item.social-sidebar li {
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.sidebar-item.social-sidebar li a {
  background: #002359 none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  display: inline-block;
  height: 50px;
  line-height: 54px;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
  font-size: 14px;
}

.sidebar-item.social-sidebar li a:hover {
  color: #ffffff !important;
}

.sidebar-item.social-sidebar li.facebook a {
  background: #3b5998 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.twitter a {
  background: #1da1f2 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.pinterest a {
  background: #bd081c none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.g-plus a {
  background: #db4437 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.linkedin a {
  background: #0077b5 none repeat scroll 0 0;
}

.sidebar-item.tags ul {
  margin-top: -8px;
}

.sidebar-item.tags ul li {
  display: inline-block;
}

.sidebar-item.tags ul li a {
  border: 1px solid #e7e7e7;
  display: inline-block;
  font-weight: 800;
  margin-top: 8px;
  margin-right: 5px;
  padding: 5px 25px;
  text-transform: capitalize;
  font-size: 13px;
  border-radius: 30px;
  color: #666666;
  background: #fafafa;
}

.sidebar-item.tags ul li a:hover {
  color: #104cba;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 50px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #104cba;
  z-index: -1;
  opacity: 0.7;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info h4 {
  color: #ffffff;
  font-weight: 700;
  line-height: 1.4;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info p {
  color: #ffffff;
}

.blog-area .sidebar .sidebar-item.add-banner .sidebar-info a {
  display: inline-block;
  color: #ffffff;
  border: 1px dashed rgba(255, 255, 255, 0.7);
  padding: 10px 25px;
  margin-top: 9px;
}

.blog-area .blog-items.content-less .item .info > a {
  margin-top: 10px;
}

/* Blog Single */
.blog-area.single .item .content-box span {
  background: #104cba none repeat scroll 0 0;
  color: #ffffff;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 3px 20px;
  text-transform: uppercase;
}

.blog-area.single .item .content-box h2,
.blog-area.single .item .content-box h3,
.blog-area.single .item .content-box h4 {
  font-weight: 500;
}

.blog-area.single .content-box .cats {
  float: left;
  margin-right: 5px;
}

.blog-area.single .content-box .meta .date {
  float: right;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog-area.single .content-box .meta {
  overflow: hidden;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.blog-area .blog-items .info > ul li {
  margin-bottom: 15px;
  color: #232323;
  position: relative;
  z-index: 1;
  padding-left: 25px;
  display: block;
}

.blog-area .blog-items .info > ul {
  margin-bottom: 25px;
  margin-top: 25px;
}

.blog-area .blog-items .info > ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  height: 100%;
  width: 100%;
  font-family: "Font Awesome 5 Free";
  color: #104cba;
  font-weight: 600;
  font-size: 14px;
}

.blog-area.single .item {
  margin-bottom: 0;
}

.blog-area .item blockquote {
  position: relative;
  z-index: 1;
  border: none;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 85px;
  color: #232323;
  font-weight: 600;
  line-height: 37px;
  background: #fafafa;
  padding: 30px;
  padding-top: 80px;
}

.blog-area .item blockquote cite {
  display: block;
  margin-top: 15px;
  color: #999999;
  font-weight: 500;
}

.blog-area .item blockquote::before {
  position: absolute;
  left: 0;
  top: 5%;
  content: "";
  height: 90%;
  width: 3px;
  background: #104cba;
}

.blog-area .item blockquote::after {
  position: absolute;
  left: 30px;
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  color: #104cba;
  font-size: 30px;
  top: 30px;
  font-weight: 600;
}

.blog-area .item blockquote p {
  margin: 0;
  position: relative;
  z-index: 1;
  line-height: 34px;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
}

.blog-area .blog-content .post-tags,
.blog-area .blog-content .share {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding-top: 15px;
  padding-bottom: 10px;
}

.blog-area .blog-content .share li {
  display: inline-block;
  margin-left: 15px;
}

.blog-area .blog-content .share li a {
  display: inline-block;
  color: #104cba;
}

.blog-area .blog-content .share li.facebook a {
  color: #3b5998;
}

.blog-area .blog-content .share li.twitter a {
  color: #1da1f2;
}

.blog-area .blog-content .share li.pinterest a {
  color: #bd081c;
}

.blog-area .blog-content .share li.g-plus a {
  color: #db4437;
}

.blog-area .blog-content .share li.linkedin a {
  color: #0077b5;
}

.blog-area .blog-content .post-tags .tags a {
  background: #f7f7f7;
  padding: 10px 15px;
  font-size: 14px;
  margin-right: 3px;
  margin-bottom: 5px;
  display: inline-block;
}

.blog-area.single .post-pagi-area {
  margin-top: 50px;
  overflow: hidden;
}

.blog-area.single .post-pagi-area a {
  display: inline-block;
  font-weight: 800;
  text-transform: capitalize;
  color: #104cba;
  border-radius: 30px;
}

.blog-area.single .post-pagi-area h5 {
  margin: 0;
  margin-top: 5px;
}

.blog-area.single .post-pagi-area a:hover {
  color: #104cba;
}

.blog-area.single.color-yellow .post-pagi-area a:hover {
  color: #ff9800;
}

.blog-area.single .post-pagi-area a:last-child {
  float: right;
}

.blog-area.single .post-pagi-area a:first-child i {
  margin-right: 3px;
}

.blog-area.single .post-pagi-area a:last-child i {
  margin-left: 3px;
}

.comments-area .commen-item .comments-info p {
  display: block;
  margin: 0;
}

.blog-area .contact-comments .submit {
  margin-bottom: 0;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Comments */

.blog-area .comments-form {
  margin-top: 50px;
}

.blog-area .blog-comments .comments-list {
  margin-bottom: 50px;
}

.blog-area .blog-comments .comments-list:last-child {
  margin-bottom: 0;
}

.comments-list .commen-item .avatar {
  float: left;
  height: 105px;
  width: 105px;
}

.comments-list .commen-item .content {
  display: table-cell;
  vertical-align: top;
}

.comments-list .commen-item .avatar img {
  height: 80px;
  width: 80px;
}

.comments-list .commen-item {
  margin-bottom: 50px;
}

.comments-list .commen-item:last-child {
  margin-bottom: 0;
}

.comments-list .commen-item.reply {
  padding-left: 80px;
}

.comments-area {
  margin-top: 40px;
}

.comments-area .comments-title h2,
.comments-area .comments-title h3,
.comments-area .comments-title h4 {
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-size: 1.5rem;
}

.comments-list .commen-item .content h3,
.comments-list .commen-item .content h4,
.comments-list .commen-item .content h5 {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
}

.comments-list .commen-item .content .title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 700;
}

.comments-list .commen-item .content .title span {
  border-left: 1px solid #e7e7e7;
  padding-left: 15px;
  margin-left: 15px;
}

.comments-info a {
  border: 1px solid #e7e7e7;
  color: #002359;
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
  padding: 1px 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.comments-info a:hover {
  color: #104cba;
}

.comments-info a i {
  margin-right: 10px;
}

.comments-form input,
.comments-form textarea {
  box-shadow: inherit;
  border: none;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 5px;
}

.comments-form textarea {
  min-height: 180px;
  padding: 15px;
}

.blog-area.full-blog.single-blog .form-group.submit {
  margin-bottom: 0;
}

.comments-form button {
  background: #104cba;
  border: 1px solid transparent;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 20px;
  padding: 15px 45px;
  text-transform: uppercase;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  border-radius: 30px;
}

.comments-form button:hover {
  color: #ffffff;
  background-color: #104cba;
  border: 1px solid transparent;
}

.comments-form .title h2,
.comments-form .title h3,
.comments-form .title h4 {
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-size: 1.5rem;
}

.blog-area .contact-comments .comments {
  margin-top: 20px;
}

.blog-area.single .blog-items .item .contact-comments .col-md-6 {
  float: left;
  padding: 0 15px;
}

/* Pagination */

.pagination li {
  display: inline-block;
  margin-top: 7px;
}

ul.pagination {
  display: block;
  margin-top: 30px;
}

.pagination li a {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 0 2px;
  color: #104cba;
  font-weight: 800;
}

.pagination li.page-item.active a {
  background: #104cba;
  border-color: #104cba;
}

/* ============================================================== 
     # Contact
=================================================================== */

.contact-area.half-bg {
  position: relative;
  z-index: 1;
}

.contact-area.half-bg::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 200px;
  width: 100%;
  background: #ffffff;
  z-index: -1;
}

.contact-area.default-padding-top.half-bg::after {
  height: 100px;
}

.contact-area .item {
  display: flex;
}

.contact-area .item i {
  display: inline-block;
  font-size: 30px;
  border-radius: 5px;
  margin-right: 35px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: #104cba;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.contact-area .item i::after {
  position: absolute;
  right: -10%;
  bottom: -10%;
  height: 100%;
  width: 100%;
  content: "";
  background: #104cba;
  z-index: -1;
  border-radius: 5px;
  opacity: 0.3;
}

.contact-area .item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd;
}

.contact-area .item:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.contact-area .item h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin-right: 25px;
}

.contact-area .item p {
  margin: 0;
  margin-top: -7px;
}

.contact-area .content {
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
}

.contact-area .content h2 {
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-area .content p {
  margin: 0;
}

.contact-area .content .heading {
  margin-bottom: 30px;
}

.contact-area .content input,
.contact-area .content textarea {
  background: #fafafa;
  border: 1px solid #f7f7f7;
  padding: 15px;
}

.contact-area .content textarea {
  min-height: 150px;
}

.contact-area .content form button {
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  color: #ffffff;
  font-weight: 600;
  padding: 15px 35px;
  border-radius: 30px;
}

.contact-area .content form img.loader {
  margin-left: 5px;
}

/* google maps*/
.google-maps iframe {
  border: medium none;
  height: 550px;
  margin-bottom: -10px;
  width: 100%;
}

.maps-area {
  overflow: hidden;
}

/* ============================================================== 
     # 404
=================================================================== */
.error-page-area .error-box {
  position: relative;
  z-index: 1;
  margin-left: 20px;
}

.error-page-area .error-box h1 {
  font-size: 160px;
  line-height: 130px;
  font-weight: 800;
  margin-bottom: 30px;
  color: #999999;
}

.error-page-area .error-box h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.error-page-area .error-box a {
  margin-top: 15px;
}

.error-page-area .thumb {
  padding-right: 35px;
}

.error-box .search {
  margin-top: 30px;
  text-align: center;
}

.error-box .search form {
  width: 400px;
  position: relative;
  z-index: 1;
}

.error-box .search .input-group {
  margin: auto;
}

.error-box .search form input {
  box-shadow: inherit;
  border-radius: inherit;
  min-height: 60px;
}

.error-box .search form button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  min-height: 60px;
  background: #104cba;
  border: none;
  padding: 0 30px;
  color: #ffffff;
}

/* ============================================================== 
     # Footer
=================================================================== */

footer {
  position: relative;
}

footer .fixed-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
}

footer.bg-dark .fixed-shape {
  opacity: 0.02;
}

footer .fixed-shape img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

footer .f-items {
  padding-bottom: 70px;
}

footer .f-items .item {
  margin-bottom: 50px;
}

footer img {
  margin-bottom: 30px;
}

footer .widget-title {
  font-weight: 600;
  margin-bottom: 30px;
}

/* Footer Top */
.footer-top .footer-top-content {
  padding: 50px 0;
  border-bottom: 1px solid #e7e7e7;
}

footer.bg-dark .footer-top .footer-top-content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-top .footer-top-content ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  padding-left: 15px;
}

.footer-top .footer-top-content ul li::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  background: #cccccc;
  border-radius: 50%;
  margin-top: -3px;
}

footer.bg-dark .footer-top .footer-top-content ul li::after {
  background: rgba(255, 255, 255, 0.3);
}

.footer-top .footer-top-content ul li a {
  color: #232323;
  font-weight: 400;
}

footer.bg-dark .footer-top .footer-top-content ul li a {
  color: #c2d1e2;
}

.footer-top .footer-top-content ul li a:hover {
  color: #104cba;
}

footer.bg-dark .footer-top .footer-top-content ul li a:hover {
  color: #ffffff;
}

.footer-top .footer-top-content form {
  position: relative;
  z-index: 1;
}

.footer-top .footer-top-content form input {
  border: none;
  color: #232323;
  border-radius: 30px;
  min-height: 60px;
  padding-left: 20px;
  background: #f1f1f1;
}

.footer-top .footer-top-content form button {
  background: linear-gradient(
    90deg,
    rgb(15 80 197) 0%,
    rgba(0, 89, 252, 1) 56%
  );
  position: absolute;
  right: 5px;
  top: 5px;
  min-height: 50px;
  width: 50px;
  color: #ffffff;
  border-radius: 50%;
}

/* Footer Main */
footer .item .social ul li {
  display: inline-block;
  margin-right: 16px;
}

footer .item .social ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 43px;
  background: #f1f1f1;
  border-radius: 50%;
  font-size: 14px;
}

footer.bg-dark .item .social ul li a {
  background: rgba(255, 255, 255, 0.1);
}

footer .item .social ul li a:hover {
  background: #104cba;
  color: #ffffff;
}

footer.bg-dark .item .social ul li a:hover {
  background: #ffffff;
  color: #104cba;
}

footer .item .social {
  margin-top: 30px;
}

footer.bg-dark p {
  color: #c2d1e2;
}

footer .f-item.link li {
  margin-bottom: 15px;
}

footer .f-item.link li a {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

footer.bg-dark .f-item.link li a {
  color: #c2d1e2;
}

footer .f-item.link li a:hover {
  color: #104cba;
}

footer .f-item.link li:last-child {
  margin-bottom: 0;
}

footer.bg-dark .f-item.link li a {
  color: #c2d1e2;
}

footer.bg-dark .f-item.link li a:hover {
  color: #ffffff;
}

footer.bg-dark p {
  color: #c2d1e2;
}

footer .f-item .address li strong {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  color: #0e2b3d;
}

footer .f-item .address li {
  margin-bottom: 20px;
}

footer .f-item .address li:last-child {
  margin-bottom: 0;
}

footer.bg-dark .f-item .address li {
  color: #c2d1e2;
}

footer.bg-dark .f-item .address li a {
  color: #c2d1e2;
}

footer .f-item .address li a {
  font-weight: 500;
  font-size: 14px;
}

footer.bg-dark .f-item .address li strong {
  color: #ffffff;
}

footer .f-item .download h5 {
  font-weight: 600;
  margin: 0;
}

footer .f-item .download {
  margin-top: 30px;
}

footer .f-item .download a {
  display: inline-block;
  margin-right: 25px;
  margin-top: 15px;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
}

footer.bg-dark .f-item .download a {
  color: #c2d1e2;
  font-weight: 500;
}

footer .f-item .download a:last-child {
  margin-right: 0;
}

footer .f-item .download a i {
  font-size: 25px;
  margin-right: 5px;
  position: relative;
  top: 3px;
  color: #104cba;
}

footer.bg-dark .f-item .download a i {
  color: #c2d1e2;
}

/* Footer Bottom */

footer .footer-bottom {
  padding: 30px 0;
  margin-top: -30px;
}

footer .footer-bottom p {
  margin: 0;
}

footer .footer-bottom p a {
  color: #104cba;
  font-weight: 500;
}

/* ============================================================== 
     # Preloader 
=================================================================== */
.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: url(../../assets/img/preloader.gif) center no-repeat #ffffff;
  text-align: center;
}

/* ============================================================== 
     # Home New 
=================================================================== */
.banner-area.auto-height.top-pad-80 .content {
  margin-top: 250px;
  padding-bottom: 200px;
  padding-top: 0;
  position: relative;
  z-index: 1;
}

.banner-area.auto-height.top-pad-80 .content p {
  font-size: 18px;
  padding-right: 20%;
  font-weight: 400;
  line-height: 1.8;
  margin: 0;
}

.banner-area.auto-height.top-pad-80 .content a {
  margin-top: 40px;
}

.banner-area.text-default .content h2 {
  font-size: 60px;
  line-height: 1.2;
}

.banner-area.text-default.content-less .content a {
  margin-top: 10px;
}

.banner-area.text-default .content h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}

.banner-area .shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100%;
}

.banner-area .shape img {
  position: absolute;
  height: auto;
  width: auto;
  z-index: 9;
}

.banner-area .shape img:first-child {
  left: 0;
  top: 0;
}

.banner-area .shape img:last-child {
  right: 0;
  bottom: 0;
  max-height: 60%;
}

@media (max-width: 1023px) {
  .banner-area .shape img {
    display: none;
  }
}

/* Services */

.illustration-services-area .single-item {
  border-right: 1px solid #e7e7e7;
}

.illustration-services-area .single-item:last-child {
  border: none;
}

.illustration-services-area .single-item .item {
  padding: 0 30px;
}

.illustration-services-area .single-item .item img {
  height: 200px;
  margin: auto 0 30px;
}

.illustration-services-area .single-item .item h4 {
  font-weight: 600;
  font-size: 18px;
}

.illustration-services-area .single-item .item p {
  margin: 0;
}

.illustration-services-area .bottom-content {
  margin-top: 80px;
}

/* About */

.about-us-area .about-content .thumb {
  height: 100%;
}

.about-us-area .about-content .thumb img:first-child {
  float: right;
  width: 70%;
  margin-bottom: 80px;
}

.about-us-area .about-content .thumb img:nth-child(2) {
  position: absolute;
  left: 50px;
  width: 60%;
  bottom: 0;
  border: 10px solid #f3f7fd;
  border-left: none;
  border-bottom: none;
}

.about-us-area .about-content .thumb img:nth-child(3) {
  height: 200px;
  width: 200px;
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -100px;
  box-shadow: 0px 0px 38px 0px rgb(0 0 0 / 8%);
  -webkit-animation-name: infiUpDown;
  animation-name: infiUpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.about-us-area .about-content .info {
  padding-left: 35px;
}

.about-us-area .about-content .info h5 {
  font-weight: 600;
  color: #104cba;
  font-size: 18px;
  margin-bottom: 25px;
}

.about-us-area .about-content .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.about-us-area .about-content .info strong {
  font-weight: 500;
  color: #232323;
  line-height: 28px;
}

.about-us-area .about-content .info a {
  margin-top: 20px;
}


/* ============================================================== 
     # Demo 
=================================================================== */

.demo-area.demo-conten.default-padding {
  padding-bottom: 30px;
}

.demo-area .single-item {
    position: relative;
    z-index: 1;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px #cccccc;
}

.demo-area .item {
    margin-bottom: 50px
}

.demo-area .single-item .info {
    padding: 30px 15px;
    text-align: center;
    background: #fafafa;
}

.demo-area .single-item .info h4 {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
    margin-top: 15px;
}
.banner-area .demo-banner h1 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 46px;
}

.banner-area .demo-banner h1 span {
    font-size: 36px;
}

.banner-area .demo-banner img {
  height: auto;
  margin-bottom: 20px;
  max-height: 60px;
}
.demo-area .def-heading h2 {
  font-weight: 900;
  margin: 30px 0;
  text-transform: uppercase;
  color: #333333;
}
.demo-footer {
  padding-bottom: 80px;
}

.introduction-area h2 span {
  color: #fd0060;
}

.introduction-area h4 {
  font-weight: 300;
  line-height: 30px;
}

.introduction-area h2 {
  color: #ffffff;
  display: inline-block;
  font-weight: 700;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.introduction-area h2::before {
  background: #fd0060 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 50px;
}

.introduction-area h2::after {
  background: #ffffff none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  width: 25px;
}

footer.demo .copyright {
  margin-top: 15px;
}
.demo-area .comming-soon.item img {
    opacity: 0.3;
}
.banner-area.demo-banner {
  padding: 50px 0;
}
.demo-area .site-heading {
  margin-bottom: 30px;
}
.demo-area img {
  background: #ffffff none repeat scroll 0 0;
  /* box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06); */
  border-radius: 8px 8px 0 0;
  padding: 10px;
}

.demo-area.demo-conten .single-item a {
    position: relative;
    display: block;
}

.demo-area.demo-conten .single-item a span {
    position: absolute;
    right: 15px;
    top: -14px;
    background: linear-gradient(90deg, rgba(30,85,189,1) 0%, rgba(67,126,235,1) 100%);
    color: #ffffff;
    padding: 1px 35px;
    border-radius: 30px;
    box-shadow: 0 0 10px #cccccc;
}

.demo-area .rtl-version a {
    position: fixed;
    right: 50px;
    bottom: 50px;
    background: linear-gradient(90deg, rgba(30,85,189,1) 0%, rgba(67,126,235,1) 100%);
    display: inline-block;
    height: 100px;
    width: 100px;
    line-height: 100px;
    z-index: 9;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    font-size: 20px;
}

.demo-area .rtl-version a::after {
    position: absolute;
    left: -10px;
    top: -10px;
    height: 120%;
    width: 120%;
    content: "";
    background: #437eeb;
    z-index: -1;
    border-radius: 50%;
    opacity: 0.3;
}

.demo-area.default-padding {
    z-index: inherit;
}

.demo-banner a.btn img {
    height: 30px;
    margin-right: 2px;
    margin-bottom: 0;
    position: relative;
}

.demo-banner a.btn {
  position: relative;
}

.banner-thumb {
  display: none;
}

.banner-area {
  position: relative;
  overflow: hidden;
}

.demo-area .site-heading h2 {
    margin-bottom: 40px;
}


.demo-area .single-item .info a {
    display: inline-block;
    padding: 5px 30px;
    border-radius: 30px;
    color: #ffffff;
    margin: 0 5px;
    background: linear-gradient(90deg, rgba(0,16,45,1) 0%, rgba(0,89,252,1) 56%);
}

.demo-area .single-item .info h4 {
    /* color: #ffffff; */
    margin-bottom: 25px;
    margin-top: 0;
}

.banner-area .fun-fact-box {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

.banner-area .fun-fact-box .fun-fact {
    display: inline-block;
    padding: 0 30px;
    position: relative;
}

.banner-area .fun-fact-box .fun-fact::after {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.6);
}

.banner-area .fun-fact-box .fun-fact:last-child::after {
    display: none;
}

.banner-area .fun-fact-box .fun-fact .timer {
    font-weight: 800;
    font-size: 30px;
    display: block;
    margin-bottom: 10px;
}

.banner-area .fun-fact-box .fun-fact .medium {
    text-transform: uppercase;
    font-weight: 700;
}

/* Features */

.demo-features .single-item {
  margin-bottom: 30px;
}

.demo-features .item {
    background: #ffffff;
    padding: 50px;
    box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
    border-radius: 8px;
}

.demo-features .item img {
    height: 50px;
    width: auto;
    margin-bottom: 15px;
}

.demo-features .item h5 {
    font-weight: 700;
    text-transform: capitalize;
}

.navbar.navbar-transparent.demo .attr-nav > ul > li.button {
    display: none;
}

.attr-nav > ul > li.button a img {
    height: 30px;
}

/* ============================================================== 
     # Home version Eight 
=================================================================== */
.banner-area.auto-height.banner-style-eight .content a {
  margin-top: 0;
}

.banner-style-eight .half-shape {
  position: absolute;
  right: 0;
  top: 0;
  background-size: cover;
  bottom: 0;
  width: 45%;
  background-position: center;
}

.banner-style-eight .rating {
  background: #ffffff;
  height: auto;
  width: auto;
  display: inline-block;
  position: absolute;
  left: -50px;
  top: 50%;
  padding: 20px;
  border-radius: 10px;
  transform: translateY(-50%);
  box-shadow: 0px 0px 38px 0px rgb(0 0 0 / 8%);
}

.banner-style-eight .rating p {
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.banner-style-eight .rating p i {
  color: #ffa900d9;
  font-size: 12px;
  margin-top: -3px;
}

.banner-style-eight .rating h4 {
  font-weight: 600;
  margin: 0;
}

.banner-style-eight .rating span {
  margin-right: 5px;
}

.services-style-eight {
  display: flex;
  align-items: center;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgb(0 0 0 / 8%);
  border-radius: 10px;
  margin-top: 30px;
}

.services-style-eight .icon {
  min-width: 250px;
  padding-right: 20px;
}

.services-style-eight:first-child {
  margin-top: 0;
}

.services-style-eight .button {
  min-width: 60px;
  text-align: right;
}

.services-style-eight .info {
  border-right: 1px solid #e7e7e7;
  padding-right: 50px;
}

.services-style-eight .button a {
  display: inline-block;
  font-size: 20px;
  border-radius: 50%;
  color: #086ad8;
}

.services-style-eight .button a i {
  font-weight: 600;
}

.services-style-eight p {
  margin-bottom: -5px;
}

.services-style-eight .icon i {
  font-size: 45px;
  color: #086ad8;
  background: rgba(8, 106, 216, 0.07);
  height: 100px;
  width: 100px;
  line-height: 105px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}

.services-style-eight:nth-child(2) .icon i {
  color: #57b957;
}

.services-style-eight:nth-child(3) .icon i {
  color: #29b2fe;
}

.award-style-two .owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

.award-style-two h2 {
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 50px;
}

.award-style-two-area {
  position: relative;
  z-index: 1;
}

.award-style-two-area .shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.choose-us-style-eight-area .fixed-bg-half {
  position: absolute;
  left: 0;
  top: 0;
  bottom: auto;
  height: 100%;
  width: 40%;
}

.choose-us-style-eight-area {
  position: relative;
  z-index: 1;
}

.choose-us-style-eight-area .info {
  max-width: 600px;
  padding-left: 50px;
  padding-right: 15px;
}

.choose-us-style-eight-area .content {
  background: #086ad8;
  padding: 120px 50px;
  text-align: center;
}

.choose-us-style-eight-area .content h4 {
  font-size: 30px;
  margin: 0;
  font-weight: 600;
}

.choose-us-style-eight-area .info > h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #1f6eff;
  font-weight: 600;
}

.choose-us-style-eight-area .info h2 {
  font-weight: 600;
  margin-bottom: 30px;
}

.choose-us-style-eight-area .info ul li {
  padding-left: 70px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.choose-us-style-eight-area .info ul li h4 {
  font-weight: 600;
}

.choose-us-style-eight-area .info ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  height: 45px;
  width: 45px;
  background: #086ad8;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
}

.choose-us-style-eight-area .info p {
  color: #cccccc;
}

.choose-us-style-eight-area .content .video-play-button {
  margin: 0;
  margin-top: 30px !important;
}

.case-studies-area.grid-items .case-items .pf-item.width {
  width: 66.6666%;
}

.case-style-two .info {
  margin-top: 15px;
}

.case-style-two .info .tags a {
  text-transform: uppercase;
  font-size: 12px;
  color: #999999;
}

.case-style-two .info a:hover {
  color: #086ad8;
}

.case-style-two .thumb {
  overflow: hidden;
  border-radius: 8px;
}

.case-style-two .thumb img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.case-style-two:hover .thumb img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.case-style-two .info h4 {
  margin: 0;
}

/* Tablet Layout: 768px.*/
@media only screen and (min-width: 768px) and (max-width: 991px) {

  .banner-style-eight .half-shape {
    width: 100%;
    z-index: 1;
}
.values-container {
  margin-left: 100px;
  margin-right: 100px;
}

.banner-style-eight .half-shape::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #000000;
    opacity: 0.6;
    z-index: -1;
}

.banner-style-eight .content h4 {
    color: #ffffff;
}

.banner-style-eight .content h2 {
    color: #ffffff;
}

.banner-style-eight .content p {
    color: #ffffff;
}

.banner-style-eight .rating {
    display: none;
}

.services-style-eight {
  display: block;
  text-align: center;
}

.services-style-eight .icon {
  padding: 0;
}

.services-style-eight .info {
  border: none;
  padding: 0;
}

.services-style-eight .button {
  text-align: center;
  margin-top: 30px;
}

.services-style-eight .button a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  border: 1px solid #e7e7e7;
  font-size: 15px;
}
.award-style-two h2 {
font-size: 36px;
margin-bottom: 30px;
}
.choose-us-style-eight-area .fixed-bg-half {
display: none;
}

.choose-us-style-eight-area .info {
padding-left: 15px;
margin-top: 50px;
}

.services-style-eight .icon img {
  max-width: 60%;
}

}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .banner-style-eight .half-shape {
      width: 100%;
      z-index: 1;
  }
  .values-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  .banner-style-eight .half-shape::after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      height: 100%;
      width: 100%;
      background: #000000;
      opacity: 0.6;
      z-index: -1;
  }

  .banner-style-eight .content h4 {
      color: #ffffff;
  }

  .banner-style-eight .content h2 {
      color: #ffffff;
  }

  .banner-style-eight .content p {
      color: #ffffff;
  }

  .banner-style-eight .rating {
      display: none;
  }

  .services-style-eight {
    display: block;
    text-align: center;
}

.services-style-eight .icon {
    padding: 0;
}

.services-style-eight .info {
    border: none;
    padding: 0;
}

.services-style-eight .button {
    text-align: center;
    margin-top: 30px;
}

.services-style-eight .button a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    border: 1px solid #e7e7e7;
    font-size: 15px;
}
.award-style-two h2 {
  font-size: 36px;
  margin-bottom: 30px;
}
.choose-us-style-eight-area .fixed-bg-half {
  display: none;
}

.choose-us-style-eight-area .info {
  padding-left: 15px;
  margin-top: 30px;
}

}

/* ============================================================== 
     # Home version Nine 
=================================================================== */
.banner-area.banner-style-nine .content h2 {
  font-size: 70px;
}

.banner-area.banner-style-nine .content {
  padding-top: 220px;
  padding-bottom: 280px;
}

.banner-style-nine .bottom-shape {
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 100% !important;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.banner-style-nine .video-button {
  margin-bottom: 70px;
}

.services-style-nine {
  margin-bottom: 30px;
}

.services-style-nine .item {
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.08);
  padding: 50px 25px;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.services-style-nine .item::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -100%;
  content: "";
  height: 100%;
  width: 100%;
  background: #104cba;
  transition: all 0.35s ease-in-out;
  z-index: -1;
}

.services-style-nine .item:hover::before {
  bottom: 0;
}

.services-style-nine .item:hover a,
.services-style-nine .item:hover p {
  color: #ffffff;
}

.services-style-nine .item:hover::after {
  background: url(../../assets/img/shape/7-light.png);
  opacity: 0.1;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

.services-style-nine .item img {
  margin: auto auto 30px;
  height: 120px;
}

.services-style-nine .item p {
  margin: 0;
}

.services-style-nine .item a,
.services-style-nine .item p {
  transition: all 0.35s ease-in-out;
}

.services-style-nine-items {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}

.services-style-nine .item i {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 60px;
  color: #086ad8;
  transition: all 0.35s ease-in-out;
}

.services-style-nine .item:hover i {
  color: #ffffff;
}

.about-style-nine .thumb {
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
}

.about-style-nine .thumb h4 {
  margin: 0;
  position: absolute;
  right: 80px;
  bottom: 0;
  padding: 40px;
  background: #ffffff;
  line-height: 1;
  font-weight: 600;
  box-shadow: 0px 2px 12px 0px #e7e7e7;
  border-radius: 30px;
}

.about-style-nine .info {
  padding-left: 35px;
}

.about-style-nine .thumb h4 strong {
  display: block;
  font-size: 120px;
  text-shadow: -5px 5px 0px #eceefe;
  -webkit-text-stroke: 2px #666666;
  color: transparent;
  font-weight: 900;
}

.about-style-nine .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.about-style-nine .info a {
  margin-top: 16px;
}

.about-style-nine .info h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #104cba;
  margin-bottom: 25px;
}

.about-style-nine .thumb::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 80px;
  width: 50%;
  background: #ffffff;
}

.about-style-nine .thumb::before {
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  height: 100%;
  width: 10px;
  background: #ffffff;
  transform: translateX(-50%);
}

.about-style-nine .thumb {
  margin-left: -10%;
}

.about-style-nine .info h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #104cba;
  margin-bottom: 25px;
}

.about-style-nine .info ul {
  margin-top: 25px;
}

.about-style-nine .info ul li {
  font-weight: 600;
  color: #232323;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
}

.about-style-nine .info ul li:last-child {
  margin-bottom: 0;
}

.about-style-nine .info ul li::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f12a";
  font-family: "Flaticon";
  font-weight: 600;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: rgba(8, 106, 216, 0.1);
  text-align: center;
  border-radius: 50%;
  color: rgba(8, 106, 216, 1);
  font-size: 12px;
}

.about-style-nine .info .contact {
  display: flex;
  border-top: 1px dashed #dddddd;
  margin-top: 30px;
  padding-top: 25px;
  justify-content: space-between;
  align-items: center;
}

.about-style-nine .info .contact p {
  margin: 0;
}

.about-style-nine .info .contact h4 {
  font-weight: 600;
  margin: 0;
}

.about-style-nine .info .contact i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #104cba;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  margin-right: 5px;
}

.about-style-nine .info .btn {
  margin-top: 30px;
}

.process-style-two {
  margin-bottom: 30px;
}

.process-style-two .item {
  padding: 40px 30px;
  background: rgba(255, 255, 255, 1);
  border-radius: 30px;
}

.process-style-two i {
  display: inline-block;
}

.process-style-two span {
  display: block;
}

.process-style-two i {
  display: inline-block;
  height: 130px;
  width: 130px;
  text-align: center;
  line-height: 140px;
  background: rgba(8, 106, 216, 1);
  border-radius: 50%;
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  margin-top: 15px;
}

.process-style-two i::after {
  position: absolute;
  left: -5%;
  top: -5%;
  content: "";
  height: 110%;
  width: 110%;
  background: #086ad8;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.2;
}

.process-style-two h5 {
  font-weight: 600;
  font-size: 20px;
  color: #232323;
}

.process-style-two span {
  display: inline-block;
  font-size: 13px;
  margin-top: 10px;
}

.process-style-two:nth-child(2n) {
  margin-top: 80px;
}

.process-style-two:nth-child(2n) i {
  height: 100px;
  width: 100px;
  line-height: 110px;
  font-size: 40px;
}

.process-style-two p {
  margin-bottom: 0;
  color: #666666;
}

/* Tablet Layout: 768px.*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-style-nine .thumb {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}
.values-container {
  margin-left: 100px;
  margin-right: 100px;
}
.about-style-nine .thumb h4 {
    display: none;
}

.about-style-nine .info {
    padding: 0;
}

.about-style-nine .info .contact p {
    display: none;
}

.process-style-two:nth-child(2n) {
    margin-top: 0;
}

.process-style-two:nth-child(2n) i {
  height: 130px;
  width: 130px;
  line-height: 140px;
  font-size: 50px;
}

}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .banner-area.banner-style-nine .content {
    padding-top: 120px !important;
    padding-bottom: 170px !important;
}
.values-container {
  margin-left: 30px;
  margin-right: 30px;
}
.banner-area.banner-style-nine .content h2 {
    font-size: 36px;
}

.about-style-nine .thumb {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.about-style-nine .thumb h4 {
    display: none;
}

.about-style-nine .info {
    padding: 0;
}

.about-style-nine .info .contact p {
    display: none;
}

.process-style-two:nth-child(2n) {
    margin-top: 0;
}

}